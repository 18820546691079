import { Component, OnInit} from '@angular/core';
import {ServiceService} from '../../services/service.service';
import {CrearMuellesService} from '../../services/crear-muelles.service';
import {FormGroup,FormControl,Validators} from '@angular/forms';
import { getMatIconFailedToSanitizeUrlError, MatDialog} from '@angular/material';
import {MyDialogComponent} from './my-dialog/my-dialog.component';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AngularFirestore,AngularFirestoreCollection } from '@angular/fire/firestore';
import 'rxjs/Rx';
import { DateAdapter } from "@angular/material";


@Component({
  selector: 'app-agenda-visual',
  templateUrl: './agenda-visual.component.html',
  styleUrls: ['./agenda-visual.component.css']
})
export class AgendaVisualComponent implements OnInit {
items: any;
muellesDia:number[][];
dia:number;
ajusteCapacidad:number;
frecuencia:boolean[];
otro:any;
heroes:any[]=[];
diaSeleccionado:boolean=false;
cargando=false;
citaDiaProvPla:boolean;
minDate=new Date();
maxDate=new Date();
hoyDate=new Date();
forma:FormGroup;
dateControl:FormControl;
nuevoPosiciones = [];
plataforma:number;
productividad:number;
nCajas:number;
fracRequerido:number;
proveedorJson:any;
usutemporal:string;
flagCitaAsignada:boolean =false;
muelleAsignado:boolean=false;
flagDialogo:boolean=false;
tiempoReal:boolean =false; //se  usa para evitar doble agendamiento
diaSem:string;
contadorFlag:number=0;
ajusteVolumen:number=1;
fechaCompleta=new Date();
nFranjas:number;
horUsu:number;
minUsu:number;

hora:string[]=[
  '6:00','6:15','6:30','6:45',
  '7:00','7:15','7:30','7:45',
  '8:00','8:15','8:30','8:45',
  '9:00','9:15','9:30','9:45',
  '10:00','10:15','10:30','10:45',
  '11:00','11:15','11:30','11:45',
  '12:00','12:15','12:30','12:45',
  '1:00','1:15','1:30','1:45',
  '2:00','2:15','2:30','2:45','3:00','3:15','3:30'];
  
nombreMuelles:string[]=[];
                  
festivos:number[]=[20221208,20221224,20221231,20230109,20230320,20230406,20230407,20230501,20230522,20230612,20230619];


  constructor(private _agendaService:ServiceService,public dialog: MatDialog,private dateAdapter: DateAdapter<any>,
              private router: Router,private crearMuellesService:CrearMuellesService,private db: AngularFirestore) { 

  this.dateAdapter.setLocale('es');
  //poner limites de fecha:
  
  if (this.hoyDate.getHours()<15){
    this.minDate.setDate(this.minDate.getDate() +1);
  } else{
    this.minDate.setDate(this.minDate.getDate() +2);
  }

  this.maxDate.setDate(this.maxDate.getDate() + 7);
  // console.log(this.hoyDate.getHours());
  // console.log(this.hoyDate.getDate()+1);
  // console.log(this.hoyDate.getDate());
  
  }

  ngOnInit() {
    this.dateControl = new FormControl('',[Validators.required]);
    
    //recuperar variables:
    let proveedor = (sessionStorage.getItem('currentUser'));
    let proveedorJson=[];
    this.proveedorJson=JSON.parse(proveedor);
    console.log(this.proveedorJson);
    this.productividad=this.proveedorJson['productividad'];
    this.plataforma = parseInt(localStorage.getItem('plataforma'));
    console.log(this.proveedorJson.empresa);
    console.log(localStorage.getItem('oc'));
    this.nCajas=parseInt(localStorage.getItem('nCajas'));
    console.log(this.productividad,this.plataforma,this.nCajas);
    
    if(sessionStorage.getItem('currentUserProvi')){
      this.usutemporal="Administrador"}
      else {this.usutemporal=this.proveedorJson['empresa']}

    Swal.fire({
      title: 'Pasos para agendar su cita:',
      text: `1) Selecciona el día 
        2) Selecciona una franja disponible`,
      // imageUrl: 'https://unsplash.it/400/200',
      // imageWidth: 400,
      // imageHeight: 200,
      // imageAlt: 'Custom image',
    })
    

    switch(this.plataforma) {
      case 60:
        this.frecuencia=[this.proveedorJson.paramBog[0],this.proveedorJson.paramBog[1],this.proveedorJson.paramBog[2],
        this.proveedorJson.paramBog[3],this.proveedorJson.paramBog[4],this.proveedorJson.paramBog[5]];
        break;
      case 93:
        this.frecuencia=[this.proveedorJson.paramMed[0],this.proveedorJson.paramMed[1],this.proveedorJson.paramMed[2],
        this.proveedorJson.paramMed[3],this.proveedorJson.paramMed[4],this.proveedorJson.paramMed[5]];
        break;
      case 95:
          this.frecuencia=[this.proveedorJson.paramCal[0],this.proveedorJson.paramCal[1],this.proveedorJson.paramCal[2],
          this.proveedorJson.paramCal[3],this.proveedorJson.paramCal[4],this.proveedorJson.paramCal[5]];
          break;
      case 122:
        this.frecuencia=[this.proveedorJson.paramBll[0],this.proveedorJson.paramBll[1],this.proveedorJson.paramBll[2],
        this.proveedorJson.paramBll[3],this.proveedorJson.paramBll[4],this.proveedorJson.paramBll[5]];
        break;
      case 127:
        this.frecuencia=[this.proveedorJson.paramBuc[0],this.proveedorJson.paramBuc[1],this.proveedorJson.paramBuc[2],
        this.proveedorJson.paramBuc[3],this.proveedorJson.paramBuc[4],this.proveedorJson.paramBuc[5]];
         break;
    }

    
    
    console.log(this.frecuencia);
  }



  round15(x:number){
    return Math.ceil(x/15)*15;}

  //cuando el usuario ingresa la fecha
  diaSeleccionadoP() {
    this.cargando=true;
    
    this.fechaCompleta=this.dateControl.value;
    // console.log(this.fechaCompleta);
    this.dia=this.dateControl.value.getFullYear()*10000+
        (this.dateControl.value.getMonth()+1)*100+this.dateControl.value.getDate();
    // console.log(this.proveedorJson.empresa,this.dia.toString(),
    // this.plataforma.toString());

    //Definiendo dia de la semana

               let diaSemNumber:number =this.dateControl.value.getDay();
               console.log(diaSemNumber);
                 if(diaSemNumber=== 0 || diaSemNumber=== 6){
                        this.diaSem = 'SD';
                 } else{
                        this.diaSem = 'LV';
                 };
      // console.log (this.plataforma,this.diaSem,diaSemNumber);

//definiendo si es la frecuencia
if (this.frecuencia[diaSemNumber-1]===true){ 
  
      //Definiento si es festivo
    console.log(this.festivos.length-1);
    var flagFestivo:boolean = false;
    if (diaSemNumber===0){
      flagFestivo=true;
    } else {
      for (var i:number=0; i<=this.festivos.length-1; i++){
        if (this.dia === this.festivos[i]){
        flagFestivo=true;}
        }};
      
     if(flagFestivo===true){
      this.frecuencia=[true,true,true,true,true,true];
       Swal.fire({
        type: 'info',
        title: 'Información:',
        text: 'Es festivo, se habilita posibilidad de entregar fuera de frecuencia, seleccione otro día por favor',
      });
      
    } else { 
    
    //NOMBRE DE MUELLE PROVISIONAL ***************************************************** 

      this.nombreMuelles = this.crearMuellesService.getNombreMuelle(this.plataforma,this.diaSem); 
    
    //Revisión si tiene cita ya asignada para ese dia
    this._agendaService.valCitaPlaDia(this.proveedorJson.empresa,this.dia.toString(),
            this.plataforma).subscribe(
              res => {
              let val:any[]=res;  
              this.citaDiaProvPla = (val.length>0) ?  true :  false;
              console.log(this.citaDiaProvPla);
              console.log(val);
              if (this.citaDiaProvPla && !this.flagCitaAsignada){
                Swal.fire({
                  type: 'info',
                  title: 'Información:',
                  text: 'Ya tiene una cita para este dia en esta plataforma',
                  footer: 'consulte sus citas'
                })
              }else{
              this.diaSeleccionado=true;
              this.buscarDia(this.dia);
              }},
              error => {
                console.error(error);});

    }
  }//fin del if cuando no es frecuencia 
    
    else {
      Swal.fire({
        type: 'info',
        title: 'Información:',
        text: 'No tiene Frecuencia para este día',
      });}
     // fin del else cuando no es festivo
  } // fin del sia seleccionado

  buscarDia(dia:number){
  // Recuperar un muelle del Firebase o crear día

    this._agendaService.getDia(dia.toString(),this.plataforma.toString()).subscribe((heroe1) =>{
//    if (this.muelleAsignado===false){
    this.otro = heroe1.payload.data();
    console.log(this.otro);
    if (this.otro) {
      console.log('on Complete');
      
      const arr:number[][]= Object.keys(this.otro).map((key) => Object.keys(this.otro[key]).map((key1) => 
      this.otro[key][key1]));
      this.otro=arr;
      console.log(this.otro);
      this.nuevoPosiciones=this.transpose(this.otro);

      this.cargando=false;
      console.log("no encontró los muelles");
      this.tiempoReal=true;
      console.log(this.tiempoReal)}
      else {
        //Se adiciona un TIMER para que la segunda vez espere 10 seg antes de crear la agenda.

        Swal.fire({
          type: 'info',
          title: 'Felicitaciones',
          text: 'Usted será el primero en agendar para este día',
        }).then((result) => {
          if (result) {
            console.log("dio clik");
            this._agendaService.getDia(dia.toString(),this.plataforma.toString()).subscribe((heroe1) =>{
              //    if (this.muelleAsignado===false){
                  this.otro = heroe1.payload.data();
                  console.log(this.otro);
                  if (this.otro) {
                    console.log();
                    
                    const arr:number[][]= Object.keys(this.otro).map((key) => Object.keys(this.otro[key]).map((key1) => 
                    this.otro[key][key1]));
                    this.otro=arr;
                    console.log(this.otro);
                    this.nuevoPosiciones=this.transpose(this.otro);
              
                    this.cargando=false;
                    console.log(this.tiempoReal);
                    this.tiempoReal=true;}
                    else {this.nuevoDia(dia);}
                  //}
                },
                  error=>{},
                  ()=>{console.log('se completo el suscribe de forma correcta');
                  }); 
         

          }
        })
        
          

  
        

      } //fin del else
    
  },
    error=>{},
    ()=>{console.log('se completo el suscribe de forma correcta');
    });
  
  //Buscar Ajustes Plataforma para temporada
  let subscription= this._agendaService.getAjustesPlataforma().subscribe((ajustes) => 
  {  let param:any;
    param= ajustes.payload.data();
    console.log(param);
    console.log(this.plataforma);


    
    if (param) {
    
        this.ajusteCapacidad=param[this.plataforma.toString()]/100;
      

    console.log(this.ajusteCapacidad);
      
    }
    else{
      console.log('No hay Param');
      this.ajusteCapacidad=1;};
        //ajuste por volumen
  if(this.nCajas>=3000) {this.ajusteVolumen=2;}
  else{if(this.nCajas>=2000) {this.ajusteVolumen=1.7}else{this.ajusteVolumen=1.5}};

  //Ajustes de productividad por plataforma
  var ajusteProd:number;
  switch(this.plataforma) {
    case 95:
      ajusteProd=1;
      break;
    case 122:
      ajusteProd=1;
      break;
    case 127:
        ajusteProd=1;
    break;
      default:
        ajusteProd=1;
      break;
  }


  //Calcula la Franja requerida
  this.fracRequerido=this.round15(this.nCajas/(this.productividad*this.ajusteCapacidad*this.ajusteVolumen*ajusteProd)*60)/15;
  // if (this.fracRequerido===1){this.fracRequerido=2};
  console.log(ajusteProd);
  console.log(this.fracRequerido);
  console.log(this.ajusteCapacidad);
  // Fin de Buscar Ajustes Plataforma
  this.horUsu=Math.floor((this.fracRequerido*15)/60);
  this.minUsu=(this.fracRequerido*15)-this.horUsu*60;
  }
    ,error=>{console.log('No se encontro nada')},
    ()=>{
    });
 
  


  }//Fin de buscarDia


  //Crear nuevo día si no existe
  nuevoDia(dia:number){

  this.tiempoReal=true;
  this.muellesDia=this.crearMuellesService.getMuelle(this.plataforma,this.diaSem);
  this._agendaService.crearMuellesDia(this.muellesDia,dia.toString(),this.plataforma.toString()).then(() => {
    console.log('Documento creado o ajustado exitósamente!');
  }, (error) => {
     console.error(error);
  });
  this.buscarDia(dia);
  let i:number;
    for (i=0; i<=this.nombreMuelles.length;i++) {
      this._agendaService.putFlagDialogPlatDia(this.dia.toString(),this.plataforma.toString(),"false",i);
    }
  //crear Directorio para reportar indicadores del Operador


} //fin de nuevo dia



transpose(array:any) {
    return array.reduce((prev, next) => next.map((item, i) =>
        (prev[i] || []).concat(next[i])
    ), []);
}

//validar franja disponible-----------------------------------------------------------
vfDispo(iFH:number,iMu:number){
//valida que nadie haya abierto el dialogo para esa plataforma para ese dia para ese muelle
this.contadorFlag=0;
this._agendaService.getflagDialogPlatDia(this.dia.toString(),this.plataforma.toString(),iMu).subscribe((heroe1) =>{
    let flag:any;
    flag= heroe1.payload.data();
    console.log(flag.name);
    console.log(this.contadorFlag);
    this.contadorFlag=this.contadorFlag+1;
    if (flag.name=="true" && this.contadorFlag==1){
      Swal.fire({
        type: 'info',
        title: 'Información:',
        text: 'En este momento estan agendando en este muelle, espera 10 segundos',
      });
      setTimeout(()=>{console.log("Desbloqueado"),
      this._agendaService.putFlagDialogPlatDia(this.dia.toString(),this.plataforma.toString(),"false",iMu);},10000); 

      }
    else if (this.contadorFlag==1){
  this._agendaService.putFlagDialogPlatDia(this.dia.toString(),this.plataforma.toString(),"true",iMu);
  if (this.citaDiaProvPla==false){
  let iFor:number;
  let max:number;
  let aDisponible:number=0;
  this.nFranjas=this.otro[0].length-1;
  console.log("nFranjas", this.nFranjas);
  
  
  //validar disponibilidad y Asigna temporarlmente la cita dentro del FOR

  if((iFH+this.fracRequerido-8)>this.nFranjas){ aDisponible=2} 
  else {
    max= ((iFH+this.fracRequerido-1)>this.nFranjas) ? this.nFranjas :(this.fracRequerido+iFH-1);
  // el -8 es para que valide por lo menos dos horas despues del fin de las franjas 
  //max= ((iFH+this.fracRequerido-1)>nFranjas) ? nFranjas :(this.fracRequerido+iFH-1);
 //validar disponibilidad FOR
  for (iFor=iFH; iFor <= max; iFor++) {
    if (this.otro[iMu][iFor]==2){
      max =(max==this.nFranjas) ? this.nFranjas: max+1;
      this.fracRequerido++;
    } else{
    aDisponible=aDisponible+this.otro[iMu][iFor];}
  } // si la franja es igual a 2, la franja requerida se incrementa y el maximo a validar se incrementa. Si no, suma.
}
  console.log(aDisponible);
  if (aDisponible==0){
    console.log("Franja Disponible");

    this.openDialog(iFH,iMu);
  
  }else {
    console.log("Franja Indisponible");
  Swal.fire({
    type: 'info',
    title: 'Información:',
    text: 'Se requiere un espacio más amplio para recibir',
    footer: 'o confirme el numero de cajas'
  });
  this._agendaService.putFlagDialogPlatDia(this.dia.toString(),this.plataforma.toString(),"false",iMu);  
  }
}else {
  Swal.fire({
    type: 'info',
    title: 'Información:',
    text: 'Solo puede tener una cita por día por Plataforma'
  });
}
}});
}
//validar franja disponible-----------------------------------------------------------

//Crear Dialogo para confirmar cita

openDialog(iFH:number,iMu:number): void {
  console.log(this.otro);
  console.log("Muelle Seleccionado: ",this.nombreMuelles[iMu]);
  console.log(iMu,iFH);
  this.tiempoReal=false;
  
  const dialogRef = this.dialog.open(MyDialogComponent, {
    data:{
      iMu,iFH
    }
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log(result);
    if (result==="ok"){
      if(this.tiempoReal===false){
      this.flagCitaAsignada=true;
      //this.muelleAsignado=true;
      this.cargando=true;
      console.log("grabar cita");
      console.log(iFH);
      console.log(this.fracRequerido);
      let iFor:number;
      let max:number;
      let aDisponible:number=0;
      let indices:string;


      console.log(iFH,this.fracRequerido,this.nFranjas);
      max= ((iFH+this.fracRequerido-1)>this.nFranjas) ? this.nFranjas :(this.fracRequerido+iFH-1);
      
  

      //grabar cita proveedor
      this.grabarCitaProv(iFH,this.fracRequerido,iMu,max);
      this._agendaService.putFlagDialogPlatDia(this.dia.toString(),this.plataforma.toString(),"false",iMu);

      ///AQUI PUEDE ESTAR EL TEMA
      
//Indisponer muelle dia plataforma

      for (iFor=iFH; iFor <= max; iFor++) {
        if (this.otro[iMu][iFor]!=2){
        indices=`${iMu}.${iFor}`;
        console.log("Insertar ", this.plataforma, indices)
        //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
        console.log("Inseeeeeeeeeeeeeeeeeerto")

        ///CANBIAR POSIBLE MAL FUNCINCIONAIENTO
      //   this.db.firestore.collection(this.dia.toString()).doc(this.plataforma.toString()).update({[indices]:1}).then(() => {
      //   console.log('Muelles puestos indisponibles');
      // }, (error) => {
      //    console.error(error, 'Muelles NOOO puestos indisponibles');
      // });

      this._agendaService.updateFranjaDiaejemplo(this.dia.toString(),this.plataforma.toString(),{[indices]:1}).then(() => {
        console.log('Muelle asignado satisfactoriamente');
      }, (error) => {
         console.error(error);
      });

      }

      }
      this.flagCitaAsignada=true;
      

      }
      else{
        Swal.fire({
          type: 'info',
          title: 'Información:',  
          text: 'Agendaron una cita en el mismo momento, por favor seleccionar otra franja'
        });
      }
        
    } else {
      console.log("No hacer nada");
      this._agendaService.putFlagDialogPlatDia(this.dia.toString(),this.plataforma.toString(),"false",iMu);  
    }
  });
}//ZZZ Fin del Dialogo

// grabar cita al proveedor

grabarCitaProv(iFH:number,minRequeridos$:number,iMu:number,max1:number){
  let hoy=new Date();
  let fechaHoy=hoy.getFullYear()*10000+(hoy.getMonth()+1)*100+ hoy.getDate();

  var horaFinal:string[]=[
    '6:00','6:15','6:30','6:45',
    '7:00','7:15','7:30','7:45',
    '8:00','8:15','8:30','8:45',
    '9:00','9:15','9:30','9:45',
    '10:00','10:15','10:30','10:45',
    '11:00','11:15','11:30','11:45',
    '12:00','12:15','12:30','12:45',
    '1:00','1:15','1:30','1:45',
    '2:00','2:15','2:30','2:45','3:00','3:15','3:30','3:45','4:00',
    '4:15','4:30','4:45','5:00','5:15','5:30','5:45','6:00'];
  
  var diasemana:string[]=['domingo','lunes','martes','miercoles','jueves','viernes','sabado'];
  
  

  let cita: Object = {
    empresa:this.proveedorJson['empresa'],
    dia:this.dia,
    fecha:this.fechaCompleta,
    minRequeridos:minRequeridos$*15,
    plataforma:this.plataforma,
    cita:this.hora[iFH],
    horaFin:horaFinal[iFH+this.fracRequerido],
    iFH:iFH,
    nFranjas:this.fracRequerido,
    muelle:(iMu+1),
    cajas:this.nCajas,
    oc:localStorage.getItem('oc'),
    fecAsig:fechaHoy,
    entregaFuera:localStorage.getItem('entregaFuera'),
    usuario:this.usutemporal,
    max:max1,
}
console.log(cita);
//validar si no agendaron una cita en el mismo momento
console.log(this.tiempoReal);
let fechaHumana:string;
fechaHumana= this.dia.toString().substr(6,2) + '/' + this.dia.toString().substr(4,2) + '/' + this.dia.toString().substr(0,4);
this._agendaService.crearCita(this.dia,cita).then((nuevo) => {
  console.log('Documento creado exitósamente!');
  Swal.fire({
    type: 'info',
    title: "",
    text: `Cita Agendada para el ${diasemana[this.dateControl.value.getDay()]} ${fechaHumana} a las ${this.hora[iFH]}, hora fin estimada ${horaFinal[iFH+this.fracRequerido]}`
  });
  
  let usuarioTemp:string = (sessionStorage.getItem('currentUserProvi'));

  if (usuarioTemp){
    //console.log('Eres operador');
    sessionStorage.removeItem('currentUser');
    sessionStorage.setItem('currentUser', usuarioTemp);
    sessionStorage.removeItem('currentUserProvi');
    this.router.navigateByUrl('/home-operador')
  }
  else   {this.router.navigateByUrl('/home');}
  }, (error) => {
  console.error(error);
});


} //fin de grabar cita

inicio(){
  let usuarioTemp:string = (sessionStorage.getItem('currentUserProvi'));

  if (usuarioTemp){
    console.log('Eres operador');
    sessionStorage.removeItem('currentUser');
    sessionStorage.setItem('currentUser', usuarioTemp);
    sessionStorage.removeItem('currentUserProvi');
    this.router.navigateByUrl('/home-operador')
  }
  else   {this.router.navigateByUrl('/home');}

}


}