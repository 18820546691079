import { Component, OnInit, OnDestroy} from '@angular/core';
import {ServiceService} from '../../services/service.service';
import {FormGroup, FormControl, Validators,AbstractControl} from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';


@Component({
  selector: 'app-indicadores-adm',
  templateUrl: './indicadores-adm.component.html',
  styleUrls: ['./indicadores-adm.component.css']
})
export class IndicadoresAdmComponent implements OnInit {
  mostrarForm:boolean=true;
  citas:any[]=[];
  forma:FormGroup;
  proveedores:any[]=[];
  cargando=true;
  codPlataforma:number;
  indicadorCita:number;
  indicadorOntime:number;
  indicadorFrecuencia:number;
  indicadorCajas:number;
  mostrarIndicadorFlag:boolean = false;
  
    constructor(private dbService:ServiceService,private router: Router) { 
  
      this.forma=new FormGroup({
        'plataforma':new FormControl('',[Validators.required]),
        'proveedor':new FormControl('',[Validators.required])
      });
      this.cargando=true;
    }
  
    mostrarInd(){
      this.mostrarForm=false;
      this.codPlataforma=parseInt(this.forma.value.plataforma);
      this.mostrarIndicadorFlag= true;
      var proveedorIndicador:string = this.forma.value.proveedor;
    
      this.dbService.getIndicadores(proveedorIndicador,parseInt(this.forma.value.plataforma)).
      subscribe((catsSnapshot) => {catsSnapshot.forEach((catData: any) => {
        this.citas.push({
          id: catData.payload.doc.id,
          data: catData.payload.doc.data()
        });  
      });
    
      if (this.citas){
        console.log('entró aqui');
        console.log(this.citas.length);
        console.log(this.citas);
    
        var acumuladoConCitas:number=0;
        var acumuladoOntime:number=0;
        var acumuladoFrecuencia:number=0; 
    
        var indAcuCajas:number[]=[];
    
        for (let i:number=0;i<this.citas.length;i++){
  
          acumuladoConCitas = (this.citas[i].data.cumplio_cita.toLowerCase().trim() != "si") ? acumuladoConCitas+1:acumuladoConCitas;
          acumuladoOntime = (this.citas[i].data.cumplio_cita.toLowerCase().trim() == "si") ? acumuladoOntime+1:acumuladoOntime;
          acumuladoFrecuencia = this.citas[i].data.frecuencia.toLowerCase().trim() == "si" ? acumuladoFrecuencia+1:acumuladoFrecuencia;
        
          let cajasReales:number=this.citas[i].data.cajas_reales;
          let cajasInformadas:number=this.citas[i].data['cajas-informadas'];
          let dispersion:number;

          if (this.citas[i].data.cajas_reales && this.citas[i].data['cajas-informadas']){
            dispersion = Math.abs(cajasReales - cajasInformadas)/cajasInformadas;
            // console.log('entro', this.citas[i].data['cajas-informadas']);
            indAcuCajas.push(dispersion);} else {console.log('espacios en blanco')}

        } 
          this.indicadorCita=Math.round((this.citas.length-acumuladoConCitas)/(this.citas.length)*1000)/1000
          this.indicadorOntime=Math.round(acumuladoOntime/(this.citas.length)*1000)/1000
          this.indicadorFrecuencia=Math.round(acumuladoFrecuencia/(this.citas.length)*1000)/1000;
          console.log(indAcuCajas);
      
          let suma:number = 0;
          indAcuCajas.forEach (function(numero){suma += numero;});
          console.log(suma);
          this.indicadorCajas=1-(Math.round(suma/indAcuCajas.length*1000)/1000);
       
        };
        this.cargando=false;
      } , ()=>{},
      ()=>{} //Fin del Subscribe
      );
      
    
      }
  
    ngOnInit() {
  
      this.forma.controls['plataforma'].valueChanges.subscribe(value => {
        console.log(value);
        this.mostrarIndicadorFlag= false;
        this.citas=[];
      });

        //traer los proveedores
        this.dbService.usuarios().subscribe((catsSnapshot) => 
        {catsSnapshot.forEach((catData: any) => {
        this.proveedores.push({
          id: catData.payload.doc.id,
          data: catData.payload.doc.data()
        });
        });
        if (this.proveedores){
         this.proveedores.sort(function (a, b) {
           if (a.data.empresa> b.data.empresa) {
             return 1;
           }
           if (a.data.empresa < b.data.empresa) {
             return -1;
           }
           // a must be equal to b
           return 0;
         });
        //  console.log(this.proveedores);
       } 
        
        // console.log(this.proveedores);
  
         }
       );
    } //Fin del ngOnInit


    home(){
      
      let usuario = (sessionStorage.getItem('currentUser'));
      let proveedorJson=[];
       proveedorJson=JSON.parse(usuario);
       var role:string = proveedorJson['role'];
       if (role==='operador'){
        this.router.navigateByUrl('/home-operador')}
        
    
        if (role==='consulta'){
          this.router.navigateByUrl('home-consulta')}
          }

}