import { Component, OnInit } from '@angular/core';
import {ServiceService} from '../../services/service.service';
import {FormGroup, FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-borrar-indicadores',
  templateUrl: './borrar-indicadores.component.html',
  styleUrls: ['./borrar-indicadores.component.css']
})
export class BorrarIndicadoresComponent implements OnInit {


  forma:FormGroup;


  constructor(private dbService:ServiceService) {

    this.forma=new FormGroup({
      'password':new FormControl('',Validators.required)
    });

    

  }
  ngOnInit() {
  }

  okContrasena(){
    if (this.forma.controls.password.value == "250279"){

    var usercollection = [
      {
        "plataforma": 122,
        "dia": "2021-03-29T05:00:00.000Z",
        "proveedor": "S C JOHNSON & SON COLOMBIANA S A"
      }

    ];
    
    //+"-"+usercollection[i].plataforma   PARA ELIMINAR SUMARLO
    for (let i:number=0; i<usercollection.length;i++) {
        this.dbService.borrarIndicador(usercollection[i].proveedor,usercollection[i].dia.substr(0,10)).then(() => {
          console.log('Documento creado o ajustado exitósamente!');
        }, (error) => {
           console.error(error);
        });
    
       }// Fin del For
      }//Fin IF contraseña
      else{console.log ("Contraseña errada")}
  }
} 
