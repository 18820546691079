import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { Router } from '@angular/router';
import {Usuario} from '../interfaces/usuario.interface';
import {ServiceService} from './service.service';
import Swal from 'sweetalert2';
import 'rxjs/add/operator/first';

@Injectable({
  providedIn: 'root'
})
export class AdminUsuService {
usuario:any={
  empresa:"0"
};
otro:any;
listaUsuariosRevision:any;
usuarioInfo:Usuario;
email:string;
private isUserLoggedIn:boolean;
public usserLogged:Usuario;

  constructor(public afAuth: AngularFireAuth,private router: Router,
              private dbService:ServiceService) {
    sessionStorage.setItem('currentUser', JSON.stringify(this.usuario));
    this.afAuth.authState.subscribe(user=>{
      //console.log('estado del usuario', user);
      if (!user){
        return
      }
      console.log('obtuvo el usuario');
      this.usuario.email=user.email;
      this.usuario.uid=user.uid;
      
    });

   }

  loginGoogle() {
    this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider());}

//Ingresar a la aplicación

  login(email:string,password:string){
    this.email=email;
    this.afAuth.auth.signInWithEmailAndPassword(email,password)
    .then(value => {
      console.log('Nice, it worked!');
      this.navigate();
    })
    .catch(err => {
      Swal.fire({
        type: 'info',
        title: 'Información:',
        text: 'Revisa por favor la contraseña o correo ingresado',
        footer: `${err}`
      });
    });
  }

  logout() {
    this.afAuth.auth.signOut();
    //console.log("Salio de la sesion");
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('currentUserProvi');
    localStorage.removeItem('token');
    this.router.navigateByUrl('/login');

  }

  signup(email: string, password: string) {
    this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then(value => {
        //console.log('Success!', value);
      })
      .catch(err => {
        console.log('Something went wrong:',err.message);
      });}

  resetPasswordInit(email: string) {
        this.afAuth.auth.sendPasswordResetEmail(email, 
            { url: 'https://agendamiento-36adf.firebaseapp.com/__/auth/action ' }).then(value => {
              Swal.fire({
                type: 'info',
                title: 'Información:',
                text: 'A su correo ha sido enviado un link para asignar una nueva contraseña'
              });
              this.router.navigateByUrl('/login');
            })
            .catch(err => {
              Swal.fire({
                type: 'info',
                title: 'Información:',
                text: 'El correo no existe, contacta al administrador'
              });
            }); 
          }
  
  
navigate() {
  this.otro=this.dbService.getUsu(this.email).valueChanges().first().subscribe(
      res => {
      this.listaUsuariosRevision = res;
       //console.log(res);
      },
      error => {
        console.error(error);
      },  
      () => {if (this.listaUsuariosRevision.length > 0) {
        let data: any = this.listaUsuariosRevision[0]; 
        
        this.setUserLoggedIn(data);
        console.log("Entro Aqui");
        let usuario = (sessionStorage.getItem('currentUser'));
        let proveedorJson=[];
        proveedorJson=JSON.parse(usuario);
        let role:string = proveedorJson['role'];
        console.log('el usuario en el admin usu es:' , usuario);
        if(!role){
          console.log('si entra al if');
          //console.log(usuario);
          //sessionStorage.setItem('currentUser', JSON.stringify(usuario));
        this.router.navigateByUrl('/home');
      } else{
        if(role==='operador'){
        this.router.navigateByUrl('/home-operador');}
        else{
          if(role==='administrador'){
          this.router.navigateByUrl('/registroAdmin');}
        else{
          if(role==='consulta'){
            this.router.navigateByUrl('/home-consulta'); }
        }
      }}
        
      }else {console.log("Contraseña o Usuario incorrecto");}
      }
    );

}


setUserLoggedIn(user:Usuario) {
  this.isUserLoggedIn = true;
  this.usserLogged = user;

  sessionStorage.setItem('currentUser', JSON.stringify(user));
  //console.log('entro a grabar al session el',  user);
}

// getUserLoggedIn() {
//   return JSON.parse(sessionStorage.getItem('currentUser'));
// }

// getUserLoggedAdmin() {
//   let usuario = (sessionStorage.getItem('currentUser'));
//   let proveedorJson=[];
//   proveedorJson=JSON.parse(usuario);
//   let role:string = proveedorJson['role'];
//   return role;
// }


}
