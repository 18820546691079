import { Injectable } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms'

@Injectable({
  providedIn: 'root'
})
export class ValidadoresService {

  constructor() { }

  qCajas(plataforma:string, cajas:string){
    return (formGroup :FormGroup) => {

      const plataformaControl = formGroup.controls[plataforma];
      const cajasControl = formGroup.controls[cajas];

      console.log(plataformaControl.value);
      if (plataformaControl.value==="60" && parseInt(cajasControl.value) <=10000 ||
          plataformaControl.value != "60" && parseInt(cajasControl.value) <=10000){
          cajasControl.setErrors(null)}
        else {
          cajasControl.setErrors({nCajas:true});
        }
      }

    }
    
  }

