import { Injectable } from '@angular/core';
import { Usuario } from '../interfaces/usuario.interface';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import { auth } from 'firebase/app';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isUserLoggedIn:boolean;
  public usserLogged:Usuario;
  private url ='https://www.googleapis.com/identitytoolkit/v3/relyingparty';
  private apikey='AIzaSyCI-efO3SUOx61ulxtqn5azVqOPFPUviSs';
  userToken:string;
  public usuario:any={};


  constructor(private http:HttpClient, private router: Router){
    this.isUserLoggedIn = false;
    this.userToken='';
   }

   setUserLoggedIn(user:Usuario) {
    this.isUserLoggedIn = true;
    this.usserLogged = user;
    sessionStorage.setItem('currentUser', JSON.stringify(user));
  }

  getUserLoggedIn() {

    let usuario = (sessionStorage.getItem('currentUser'));
    let proveedorJson=[];
    proveedorJson=JSON.parse(usuario);
    //console.log('el usuario del guard',usuario);
    var role:string = proveedorJson['role'];
    if (proveedorJson['empresa']!="0" || role==='consulta'){
    return 1;}
  }

  }

