import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl, Validators,AbstractControl} from '@angular/forms';
import { Router } from '@angular/router';
import {ServiceService} from '../../services/service.service';


@Component({
  selector: 'app-agendaop',
  templateUrl: './agendaop.component.html',
  styleUrls: ['./agendaop.component.css']
})
export class AgendaopComponent implements OnInit {

forma:FormGroup;
invalido:boolean=false;
usuarios:any[]=[];
usuariosTem:any[]=[];
cargando=true;
proveedores:any[]=[];

  constructor(private router: Router,private _agendaService:ServiceService) { 
    this.forma=new FormGroup({
      'plataforma':new FormControl('',[Validators.required]),
      'proveedor':new FormControl('',[Validators.required]),
      'motivo':new FormControl('',[Validators.required]),
      'nCajas':new FormControl('',[Validators.required,Validators.pattern("[0-9]+")]),
      'oc':new FormControl('',[Validators.required])
    });

  }

  ngOnInit() {
        //traer los proveedores
        this._agendaService.usuarios().subscribe((catsSnapshot) => 
        {catsSnapshot.forEach((catData: any) => {
        this.usuarios.push({
          id: catData.payload.doc.id,
          data: catData.payload.doc.data()
        });      
        });
        this.cargando=false;
        if (this.usuarios){this.proveedores=this.usuarios}
        if (this.proveedores){
          console.log(this.proveedores)
         this.proveedores.sort(function (a, b) {
           if (a.data.empresa> b.data.empresa) {
             return 1;
           }
           if (a.data.empresa < b.data.empresa) {
             return -1;
           }
           // a must be equal to b
           return 0;
         });
         console.log(this.proveedores);
       } 
  
         },()=>{},()=>{console.log("Se completo")});



  }
  navegar() {
  
    console.log(this.forma);
    if (this.forma.valid){
    localStorage.setItem('plataforma', this.forma.value.plataforma);
    //localStorage.setItem('empresa', this.forma.value.proveedor);
    localStorage.setItem('nCajas', this.forma.value.nCajas);
    localStorage.setItem('oc', this.forma.value.oc);
    localStorage.setItem('entregaFuera', this.forma.value.motivo);

    const index = this.usuarios.findIndex(proveedor => proveedor.data.empresa === this.forma.value.proveedor);
    
    console.log(index); // 3
    console.log(this.usuarios[index]); // blueberries
    let frecAbiertas:boolean[]=[true,true,true,true,true,true];
    this.usuarios[index].data.paramCal=frecAbiertas;
    this.usuarios[index].data.paramBll=frecAbiertas;
    this.usuarios[index].data.paramMed=frecAbiertas;
    this.usuarios[index].data.paramBog=frecAbiertas;
    this.usuarios[index].data.paramBuc=frecAbiertas;
    let usuarioTemp = (sessionStorage.getItem('currentUser'));
    let proveedorJson=[];
    proveedorJson=JSON.parse(usuarioTemp);
    sessionStorage.setItem('currentUserProvi', JSON.stringify(proveedorJson));
    this.usuarios[index].data.role='operador';
    sessionStorage.setItem('currentUser', JSON.stringify(this.usuarios[index].data));
    this.router.navigateByUrl('/agendaVisual');

  }
  else{
    this.invalido=true;
  }
}

}
