import { Component, OnInit, OnDestroy,} from '@angular/core';
import {ServiceService} from '../../services/service.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AdminUsuService } from '../../services/admin-usu.service';
import {Observable, Subscription} from "rxjs";

@Component({
  selector: 'app-citas-asignadas',
  templateUrl: './citas-asignadas.component.html',
  styleUrls: ['./citas-asignadas.component.css']
})
export class CitasAsignadasComponent implements OnInit{

  citas:any[]=[];
  diaMuelles:any;
  proveedorJson:any[]=[];
  heroes:any[]=[];
  cargando=true;
  otro:any;
  subscription: Subscription;
  subsFlag:boolean=false;
  iHour:number;
  mostrar1:boolean;
  fechaMan:Date;
  fechaManString:number;
  fechaHoy:Date;
  fechaHoyString:number;


  constructor(private bdService:ServiceService,
              private router: Router,private authUsuService: AdminUsuService) {

      let proveedor = (sessionStorage.getItem('currentUser'));
      this.proveedorJson=JSON.parse(proveedor);
      console.log(this.proveedorJson);
      let nomProv:string=this.proveedorJson['empresa'];
      let hoy=new Date();
      let iFor:number;

      // Hasta esta hora se pueden eliminar citas de tomorrow
      this.iHour= (hoy.getHours()<15)? 1:2;
      
      let DIA_EN_MILISEGUNDOS:number;
      DIA_EN_MILISEGUNDOS=24*60*60*1000;
      this.fechaMan = new Date(hoy.getTime()+DIA_EN_MILISEGUNDOS);
      this.fechaManString = this.fechaMan.getFullYear()*10000+(this.fechaMan.getMonth()+1)*100+ this.fechaMan.getDate();
      console.log(this.fechaManString);
      this.fechaHoy= new Date(hoy.getTime());
      this.fechaHoyString = this.fechaHoy.getFullYear()*10000+(this.fechaHoy.getMonth()+1)*100+ this.fechaHoy.getDate();

      //console.log(fechaMan);
      
      this.cargando=true;
      for (iFor=0;iFor<=7;iFor++) {
     
        DIA_EN_MILISEGUNDOS=24*60*60*1000*iFor;
        let fechaCita:Date = new Date(hoy.getTime()+DIA_EN_MILISEGUNDOS);
        let fechaCitaString:number = fechaCita.getFullYear()*10000+(fechaCita.getMonth()+1)*100+ fechaCita.getDate();
        console.log(fechaCitaString);
        let subscription= this.bdService.getCitas(nomProv,fechaCitaString.toString()).subscribe((catsSnapshot) => {
          this.otro=catsSnapshot;
          console.log(this.citas,iFor);

          // console.log(this.otro);
          catsSnapshot.forEach((catData: any) => {
            this.citas.push({
              id: catData.payload.doc.id,
              data: catData.payload.doc.data(),
              
              
            });
          });
          this.cargando=false;
//No carga
        });        
      }
      
      console.log(this.citas);

} //Fin del constructor

  ngOnInit() {

    }

  

  eliminarCita(id:string,dia:number,plataforma:number,muelle:number,iFH:number,
              fracRequerido:number,i:number){
      
      Swal.fire({
      title: 'Está seguro?',
      text: `Esta seguro que desea borrar esta cita de la Plataforma ${plataforma | plataforma}`,
      type: 'question',
      showConfirmButton: true,
      showCancelButton: true
      }).then(resp => {
      if (resp.value){
      this.cargando=true;

      console.log(this.citas);
      this.citas = this.citas.filter(function (cita) {
        return cita.data.dia !== dia;
      });

      
      //console.log(this.citas);

      this.bdService.borrarCita(id,dia.toString()).then(() => {
      console.log('Documento borrado exitósamente!');
      }, (error) => {
      console.error(error);
      });


  
    console.log("Id a borrar", id);
    console.log(id,dia,plataforma,muelle,iFH,fracRequerido);

   this.subscription=this.bdService.cleanCitaMuelleDia(dia.toString(),plataforma.toString()).subscribe((heroe1) =>{
      this.diaMuelles = heroe1.payload.data();
      this.subsFlag=true;
      if (this.diaMuelles) {
      console.log(this.diaMuelles);
      // this.crearArreglo(this.diaMuelles);
      console.log(this.diaMuelles);
      this.liberarMuelle(dia,plataforma,muelle,iFH,fracRequerido);
      }
      else {console.log("Error no existe en DB");}
  });
  
}
})
} 

  liberarMuelle(dia:number,plataforma:number,muelle:number,iFH:number,fracRequerido:number){
  console.log(this.diaMuelles);
  let nFranjas=this.diaMuelles[0].length-1;
  let iMu:number=muelle-1;
  let iFor:number;
  let max:number;
  let indices:string;
  console.log("nFranjas", nFranjas);
  max= ((iFH+fracRequerido-1)>=nFranjas) ? nFranjas :(fracRequerido+iFH-1);
  for (iFor=iFH; iFor <= max; iFor++) {
    if (this.diaMuelles[iMu][iFor]!=2){
      indices=`${iMu}.${iFor}`;
      this.bdService.updateFranjaDiaejemplo(dia.toString(),plataforma.toString(),{[indices]:0}); 
  }}}


  ngOnDestroy() {
    if (this.subsFlag===true){
    this.subscription.unsubscribe();}
  }
}
