import { Component, OnInit,Inject} from '@angular/core';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';




@Component({
  selector: 'app-my-dialog',
  templateUrl: './my-dialog.component.html',
  styleUrls: ['./my-dialog.component.css']
})
export class MyDialogComponent implements OnInit {

  hora:string[]=[
    '6:00','6:15','6:30','6:45',
    '7:00','7:15','7:30','7:45',
    '8:00','8:15','8:30','8:45',
    '9:00','9:15','9:30','9:45',
    '10:00','10:15','10:30','10:45',
    '11:00','11:15','11:30','11:45',
    '12:00','12:15','12:30','12:45',
    '1:00','1:15','1:30','1:45',
    '2:00','2:15','2:30','2:45', '3:00'];

  muelle:string[]=['Muelle 1','Muelle 2','Muelle 3','Muelle 4','Muelle 5','Muelle 6',
  'Muelle 7','Muelle 8','Muelle 9','Muelle 10','Muelle 11','Muelle 12','Muelle 13'];


  constructor(public dialogRef: MatDialogRef<MyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      console.log(data.iMu,data.iFH)
      setTimeout(()=>{console.log("tiempo terminado"),
      this.dialogRef.close("cancelado")},10000); 

  }
  ngOnInit() {

  }

  save(){
    this.dialogRef.close("ok");
    //save de document
  }

  cancelar(){
    this.dialogRef.close("cancelado");
    //save de document
  }

}
