import { Component } from '@angular/core';
import {AdminUsuService} from '../../services/admin-usu.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-barra-lateral',
  templateUrl: './barra-lateral.component.html',
  styleUrls: ['./barra-lateral.component.css']
})
export class BarraLateralComponent {



  constructor(private adminUsuService:AdminUsuService,private router: Router) {}

  cerrarSesion(){
    console.log("entro a la barra");
    this.adminUsuService.logout();
  }

  perfil(){}

}
