import { Component, OnInit,OnDestroy} from '@angular/core';
import {ServiceService} from '../../services/service.service';
import {FormGroup,FormControl,Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {Observable, Subscription} from "rxjs";
import { Router } from '@angular/router';

@Component({
  selector: 'app-citas-dia-pla',
  templateUrl: './citas-dia-pla.component.html',
  styleUrls: ['./citas-dia-pla.component.css']
})

export class CitasDiaPlaComponent implements OnInit {
  citas:any[]=[];
  cargando=true;
  minDate=new Date();
  maxDate=new Date();
  dateControl:FormControl;
  forma:FormGroup;
  dia:number;
  mostrar=true;
  plataforma:number;
  sincitas:boolean= false;
  diaMuelles:any;
  proveedorJson:any[]=[];
  heroes:any[]=[];
  otro:any;
  subscription: Subscription;
  subsFlag:boolean=false;
  cajasAcumulado:number=0;
  numeroProveedores:number;

  constructor(private _agendaService:ServiceService,private router: Router) { 
  
    //poner limites de fecha:
  this.minDate.setDate(this.minDate.getDate());
  this.maxDate.setDate(this.maxDate.getDate() + 6);
  }

  ngOnInit() {
      this.forma=new FormGroup({
        'plataforma':new FormControl('',[Validators.required])
      });
    this.dateControl = new FormControl('',[Validators.required]);
  }

platSeleccionada(){
  this.plataforma=parseInt(this.forma.value.plataforma);
  this.dia=this.dateControl.value.getFullYear()*10000+
  (this.dateControl.value.getMonth()+1)*100+this.dateControl.value.getDate();
  let plataforma=parseInt(this.forma.value.plataforma);
  console.log(this.dia,plataforma);

  this._agendaService.getCitasDiaPla(this.dia.toString(),plataforma).
    subscribe((catsSnapshot) => {
      // console.log(catsSnapshot);
      catsSnapshot.forEach((catData: any) => {
      // console.log(catData);
      this.citas.push({
        id: catData.payload.doc.id,
        data: catData.payload.doc.data()
      });
    });
    if (this.citas){

      this.citas.sort(function (a, b) {
        let horaCita_a:number;
        let horaCita_b:number;
        let hora:number;
        let minutos:number;
        let posicion:number;
        posicion=a.data.cita.lastIndexOf(":");
        hora=parseInt(a.data.cita.slice(0,posicion));
        minutos=parseInt(a.data.cita.slice(-2));
        if(hora<5){hora=hora+12};
        horaCita_a=(hora*216000+minutos*3600)/5184000;

        posicion=b.data.cita.lastIndexOf(":");
        hora=parseInt(b.data.cita.slice(0,posicion));
        minutos=parseInt(b.data.cita.slice(-2));
        if(hora<5){hora=hora+12};
        horaCita_b=(hora*216000+minutos*3600)/5184000;

        if (horaCita_a> horaCita_b) {
          return 1;
        }
        if (horaCita_a < horaCita_b) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
     //  console.log(this.proveedores);
    } 

    
    for (let i:number=0;i<this.citas.length;i++){
      this.cajasAcumulado=this.citas[i].data.cajas+this.cajasAcumulado;
    }
    this.numeroProveedores=this.citas.length;

    this.cargando=false;
    this.mostrar = (this.citas.length==0)? true:false;
    this.sincitas= (this.citas.length==0)? true:false;
    console.log(this.citas.length);
    console.log(this.citas);
  }
  );
  }

  eliminarCita(id:string,dia:number,plataforma:number,muelle:number,iFH:number,
    fracRequerido:number,i:number){

Swal.fire({
title: 'Está seguro?',
text: `Esta seguro que desea borrar esta cita de la Plataforma ${plataforma}`,
type: 'question',
showConfirmButton: true,
showCancelButton: true
}).then(resp => {
if (resp.value){
this.cargando=true;

console.log(this.citas);
this.citas = this.citas.filter(function (cita) {
return cita.data.dia !== dia;
});


//console.log(this.citas);

this._agendaService.borrarCita(id,dia.toString()).then(() => {
console.log('Documento borrado exitósamente!');
}, (error) => {
console.error(error);
});



console.log("Id a borrar", id);
console.log(id,dia,plataforma,muelle,iFH,fracRequerido);

this.subscription=this._agendaService.cleanCitaMuelleDia(dia.toString(),plataforma.toString()).subscribe((heroe1) =>{
this.diaMuelles = heroe1.payload.data();
this.subsFlag=true;
if (this.diaMuelles) {
console.log(this.diaMuelles);
// this.crearArreglo(this.diaMuelles);
console.log(this.diaMuelles);
this.liberarMuelle(dia,plataforma,muelle,iFH,fracRequerido);
}
else {console.log("Error no existe en DB");}
});

}
})
} //FIn de eliminar cita


//Crear Matriz cuando viene de Fireb¿ase
// crearArreglo(heroesObj:object){
//   if (heroesObj===null){return [];}
//   else {
//   Object.keys(heroesObj).forEach(key=>{
//     const heroe:any=heroesObj[key];
//     this.heroes.push(heroe);
//     return this.heroes;
//   });
//   this.diaMuelles=this.heroes;
//   this.heroes=[];

// }}

liberarMuelle(dia:number,plataforma:number,muelle:number,iFH:number,fracRequerido:number){
console.log(this.diaMuelles);
let iMu:number=muelle-1;
let iFor:number;
let max:number;
let indices:string;
max= ((iFH+fracRequerido-1)>36) ? 36 :(fracRequerido+iFH);
for (iFor=iFH; iFor <= max; iFor++) {
if (this.diaMuelles[iMu][iFor]!=2){
indices=`${iMu}.${iFor}`;
this._agendaService.updateFranjaDiaejemplo(dia.toString(),plataforma.toString(),{[indices]:0})  
}
}
}


ngOnDestroy() {
if (this.subsFlag===true){
this.subscription.unsubscribe();}
}

home(){
      
  let usuario = (sessionStorage.getItem('currentUser'));
  let proveedorJson=[];
   proveedorJson=JSON.parse(usuario);
   var role:string = proveedorJson['role'];
   if (role==='operador'){
    this.router.navigateByUrl('/home-operador')}
    

    if (role==='consulta'){
      this.router.navigateByUrl('home-consulta')}
      }

}
