import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fechaHumana'
})
export class FechaHumanaPipe implements PipeTransform {

  transform(value: number, ...args: any[]): any {
    let fechaHumana:string;
    fechaHumana= value.toString().substr(6,2) + '/' + value.toString().substr(4,2) + '/' + value.toString().substr(0,4);

    return fechaHumana;          
     
  }

}
