import { NgModule } from '@angular/core';
import {RegistroComponent} from './component/registro/registro.component';
import { LoginAuthComponent } from './component/login-auth/login-auth.component';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from './component/login/login.component';

import {RegistroAdminComponent} from './component/registro-admin/registro-admin.component';
import {AgendaComponent} from './component/agenda/agenda.component';
import {CitasDiaPlaComponent} from './component/citas-dia-pla/citas-dia-pla.component';
import {CitasAsignadasComponent} from './component/citas-asignadas/citas-asignadas.component';
import {CambiopassComponent} from './component/cambiopass/cambiopass.component';
import {AgendaVisualComponent} from './component/agenda-visual/agenda-visual.component';
import {GuardService} from './services/guard.service';
import {GuardAdminServiceService} from './services/guard-admin-service.service';
import {GuardOperadorService} from './services/guard-operador.service';
import {HomeComponent} from './component/home/home.component';
import{BarraLateralComponent} from './component/barra-lateral/barra-lateral.component';
import {UsersComponent} from './component/users/users.component';
import {HomeOperalogComponent} from './component/home-operalog/home-operalog.component';
import {AgendaopComponent} from './component/agendaop/agendaop.component';
import {ModificarProvComponent} from './component/modificar-prov/modificar-prov.component';
import {ParamPlatComponent} from './component/param-plat/param-plat.component';
import {VerParamComponent} from './component/ver-param/ver-param.component';
import {IndicadoresComponent} from './component/indicadores/indicadores.component';
import {ContactosComponent} from './component/contactos/contactos.component';
import {ExcelLoaderComponent} from './component/excel-loader/excel-loader.component';
import {AyudaComponent} from './component/ayuda/ayuda.component';
import {BorrarIndicadoresComponent} from './component/borrar-indicadores/borrar-indicadores.component';
import {IndicadoresAdmComponent} from './component/indicadores-adm/indicadores-adm.component';
import { InIndicadorComponent } from './component/in-indicador/in-indicador.component';
import {SegOperadorComponent} from './component/seg-operador/seg-operador.component';
import { SinCitaComponent } from './component/sin-cita/sin-cita.component';
import { HomeConsultaComponent } from './component/consulta/home-consulta/home-consulta.component';



const routes: Routes = [
  //Proveedor
  { path: 'login', component: LoginAuthComponent },
  { path: 'cambio', component: CambiopassComponent},
  { path: 'home', component: HomeComponent,canActivate: [GuardService]},
  { path: 'agenda', component: AgendaComponent,canActivate: [GuardService]},
  { path: 'agendaVisual', component: AgendaVisualComponent,canActivate: [GuardService]},
  { path: 'citas', component: CitasAsignadasComponent,canActivate: [GuardService]},
  { path: 'frecuencias', component: VerParamComponent,canActivate: [GuardService]},
  { path: 'indicadores', component: IndicadoresComponent,canActivate: [GuardService]},
  { path: 'contactos', component: ContactosComponent,canActivate: [GuardService]},
  { path: 'tutoriales', component: AyudaComponent},

  //Operador
  { path: 'home-operador', component: HomeOperalogComponent,canActivate: [GuardOperadorService]},
  { path: 'citas-dia-pla', component: CitasDiaPlaComponent, canActivate: [GuardOperadorService]},
  { path: 'registro', component: RegistroComponent},
  { path: 'proveedores', component: UsersComponent,canActivate: [GuardOperadorService]},
  { path: 'agendarcita', component: AgendaopComponent,canActivate: [GuardOperadorService]},
  { path: 'modificar-proveedor', component: ModificarProvComponent,canActivate: [GuardOperadorService]},
  { path: 'parametros-plataforma', component: ParamPlatComponent,canActivate: [GuardOperadorService]},
  { path: 'subir-indicadores', component: ExcelLoaderComponent,canActivate: [GuardOperadorService]},
  { path: 'borrar-indicadores', component: BorrarIndicadoresComponent,canActivate: [GuardOperadorService]},
  { path: 'indicadoresAdm', component: IndicadoresAdmComponent,canActivate: [GuardOperadorService]},
  { path: 'ingresarIndicadores', component: InIndicadorComponent,canActivate: [GuardOperadorService]},
  { path: 'seguimientoOperacion', component: SegOperadorComponent,canActivate: [GuardOperadorService]},
  { path: 'sinCita', component: SinCitaComponent,canActivate: [GuardOperadorService]},

  //Consulta
  { path: 'home-consulta', component: HomeConsultaComponent,canActivate: [GuardOperadorService]},
  { path: 'citas-consulta', component: CitasDiaPlaComponent,canActivate: [GuardOperadorService]},
  { path: 'indicadoresConsulta', component: IndicadoresAdmComponent,canActivate: [GuardOperadorService]},

  //Administrador
  { path: 'registroAdmin', component: RegistroAdminComponent,canActivate: [GuardAdminServiceService]},
  { path: '**', pathMatch: 'full', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
