import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl, Validators,AbstractControl,FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';
import { ValidadoresService } from 'src/app/services/validadores.service';

@Component({
  selector: 'app-root',
  templateUrl:'./agenda.component.html',
})
export class AgendaComponent implements OnInit {

  invalido:boolean=false;
forma:FormGroup;

    constructor(private router: Router, private validadores:ValidadoresService,private fb: FormBuilder) {



      this.forma=this.fb.group({
        plataforma: ['',[Validators.required]],
        nCajas:['',[Validators.required,Validators.pattern("[0-9]+")]],
        oc:['',[Validators.required]]
      },{
        validators: this.validadores.qCajas('plataforma','nCajas')
      });
      console.log(this.forma);
    }

      
   ngOnInit(){}


   navegar() {
    if (this.forma.valid){
    localStorage.setItem('plataforma', this.forma.value.plataforma);
    localStorage.setItem('nCajas', this.forma.value.nCajas);
    localStorage.setItem('oc', this.forma.value.oc);
    localStorage.setItem('entregaFuera', "No");
    this.router.navigateByUrl('/agendaVisual');
  }
  else{
    this.invalido=true;
  }
}
}
