import { Component, OnInit} from '@angular/core';
import {ServiceService} from '../../services/service.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styles: []
})
export class UsersComponent implements OnInit {

  usuarios:any[]=[];
  usuariosTem:any[]=[];
  cargando=false;

  constructor(private _agendaService:ServiceService,private router: Router) {

  }

  ngOnInit() {
    console.log(this.usuarios);
    this.cargando=true;
    let subscription= this._agendaService.usuarios().subscribe((catsSnapshot) => 
    {catsSnapshot.forEach((catData: any) => {
    this.usuarios.push({
      id: catData.payload.doc.id,
      data: catData.payload.doc.data()
    });
   
    });
    console.log(this.usuarios);
    this.cargando=false;
    //subscription.unsubscribe();
     }  
           
     );
  console.log(this.usuarios);    }


  eliminarUsuario(i:number){
    let emailB:string =this.usuarios[i].id;
    //this.usuarios.splice(i,1);
    this.usuarios=[];
    this._agendaService.borrarUsuario(emailB).then(() => {
      console.log('Usuario borrado exitósamente!');
      }, (error) => {
      console.error(error);
      });
    }

    modificarUsuario(i:number){
      console.log(this.usuarios[i]);
      localStorage.setItem('mod-prov', JSON.stringify(this.usuarios[i]));
      this.router.navigateByUrl('/modificar-proveedor');
      
      }

   

}
