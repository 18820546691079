import { Component, OnInit } from '@angular/core';
import {ServiceService} from '../../services/service.service';
import {AdminUsuService} from '../../services/admin-usu.service';
import {FormGroup, Validators,AbstractControl,FormBuilder, FormArray} from '@angular/forms';
import 'rxjs/Rx';
import {Usuario} from '../../interfaces/usuario.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modificar-prov',
  templateUrl: './modificar-prov.component.html',
  styleUrls: ['./modificar-prov.component.css']
})
export class ModificarProvComponent implements OnInit {

forma:FormGroup;
private dia:string[]=['Lunes','Martes','Miercoles','Jueves','Viernes','Sabado'];
private diaCorto:string[]=['L','M','X','J','V','S'];
email:string;
usuario1:Usuario={ 
  nombre:"",
  apellido:"",
  email:"",
  password:"",
  empresa:"",
  observaciones:""
}

  constructor(private bdService:ServiceService, private router: Router,
    private auth:AdminUsuService,private fb: FormBuilder) { 
      this.crearFormulario();

    }

  ngOnInit() {
  }

  crearFormulario(){

    let usuario1 = (localStorage.getItem('mod-prov'));
    let proveedorJson=[];
    proveedorJson=JSON.parse(usuario1);
    console.log(proveedorJson);

    //let role:string = proveedorJson['role'];

    this.email=proveedorJson['data'].email;

    this.forma=this.fb.group({
  
      nombre:[proveedorJson['data'].nombre,[Validators.required,Validators.minLength(3)]],
      apellido:[proveedorJson['data'].apellido,Validators.required],
      empresa:[proveedorJson['data'].empresa,Validators.required],
      productividad:[proveedorJson['data'].productividad,Validators.required],
      paramBog:this.fb.array([[proveedorJson['data'].paramBog[0]],[proveedorJson['data'].paramBog[1]],[proveedorJson['data'].paramBog[2]],[proveedorJson['data'].paramBog[3]],[proveedorJson['data'].paramBog[4]],[proveedorJson['data'].paramBog[5]]]),
      paramMed:this.fb.array([[proveedorJson['data'].paramMed[0]],[proveedorJson['data'].paramMed[1]],[proveedorJson['data'].paramMed[2]],[proveedorJson['data'].paramMed[3]],[proveedorJson['data'].paramMed[4]],[proveedorJson['data'].paramMed[5]]]),
      paramCal:this.fb.array([[proveedorJson['data'].paramCal[0]],[proveedorJson['data'].paramCal[1]],[proveedorJson['data'].paramCal[2]],[proveedorJson['data'].paramCal[3]],[proveedorJson['data'].paramCal[4]],[proveedorJson['data'].paramCal[5]]]),
      paramBuc:this.fb.array([[proveedorJson['data'].paramBuc[0]],[proveedorJson['data'].paramBuc[1]],[proveedorJson['data'].paramBuc[2]],[proveedorJson['data'].paramBuc[3]],[proveedorJson['data'].paramBuc[4]],[proveedorJson['data'].paramBuc[5]]]),
      paramBll:this.fb.array([[proveedorJson['data'].paramBll[0]],[proveedorJson['data'].paramBll[1]],[proveedorJson['data'].paramBll[2]],[proveedorJson['data'].paramBll[3]],[proveedorJson['data'].paramBll[4]],[proveedorJson['data'].paramBll[5]]]),
      observaciones:[proveedorJson['data'].observaciones],
      email:[proveedorJson['data'].email]
      
    });
  

} // Fin de Crear Formulario

get paramBogota(){
  return this.forma.get('paramBog') as FormArray;}
get paramMedellin(){
    return this.forma.get('paramBog') as FormArray;}
get paramCali(){
    return this.forma.get('paramBog') as FormArray;}
get paramBuca(){
    return this.forma.get('paramBog') as FormArray;}
get paramBarra(){
    return this.forma.get('paramBog') as FormArray;}

guardarCambios(){
  console.log(this.forma);
  if (this.forma.valid){
  this.usuario1=this.forma.value;
  console.log(this.usuario1);
  this.bdService.nuevoUsuario(this.forma.value).then(() => {
      console.log('Documento creado exitósamente!');
    }, (error) => {
      console.log(error);
    });

  console.log(this.forma.value);
  console.log(this.forma);
  this.router.navigateByUrl('/proveedores');

}
  else {
    console.log("Formulario invalido");

  }
  }

}