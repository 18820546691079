import { Injectable } from '@angular/core';
import { AngularFirestore,AngularFirestoreCollection } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AjustesPlaService {

  constructor(private db:AngularFirestore) { }


public ajustesPlataforma(dia:string, forma:any){
    return this.db.collection(dia).doc('parametros').set(forma);}


      

}