import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CrearMuellesService {

   //Plataforma Bogota
   
  private platLV60:any =
  {
  0:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:0,29:0,30:0,31:0,32:2,33:2,34:2,35:2,36:0,37:0},
  1:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:0,29:0,30:0,31:0,32:2,33:2,34:2,35:2,36:0,37:0},
  2:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:0,29:0,30:0,31:0,32:2,33:2,34:2,35:2,36:0,37:0},
  3:{0:2,1:2,2:2,3:2,4:2,5:2,6:2,7:2,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:0,29:0,30:0,31:0,32:2,33:2,34:2,35:2,36:0,37:0},
  4:{0:2,1:2,2:2,3:2,4:2,5:2,6:2,7:2,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:0,29:0,30:0,31:0,32:2,33:2,34:2,35:2,36:0,37:0},
  5:{0:2,1:2,2:2,3:2,4:2,5:2,6:2,7:2,8:2,9:2,10:2,11:2,12:2,13:2,14:2,15:2,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:0,29:0,30:0,31:0,32:2,33:2,34:2,35:2,36:0,37:0},  
  6:{0:2,1:2,2:2,3:2,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:0,29:0,30:0,31:0,32:2,33:2,34:2,35:2,36:0,37:0},
  7:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:0,29:0,30:0,31:0,32:2,33:2,34:2,35:2,36:0,37:0},
  8:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:0,29:0,30:0,31:0,32:2,33:2,34:2,35:2,36:0,37:0},
  9:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:0,29:0,30:0,31:0,32:2,33:2,34:2,35:2,36:0,37:0},
  10:{0:2,1:2,2:2,3:2,4:2,5:2,6:2,7:2,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:0,29:0,30:0,31:0,32:2,33:2,34:2,35:2,36:0,37:0},
  11:{0:2,1:2,2:2,3:2,4:2,5:2,6:2,7:2,8:2,9:2,10:2,11:2,12:2,13:2,14:2,15:2,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:0,29:0,30:0,31:0,32:2,33:2,34:2,35:2,36:0,37:0},
};
 
 private muelleLV60:string[]=['Muelle 3','Muelle 4','Muelle 5','Muelle 6','Muelle 7','Muelle 8','Muelle 9','Mue 10','Mue 11','Mue 12','Mue 13','Mue 14'];
//Muelle 9 al 14 excluivos para alimentos
 //private muelleLV60:string[]=['1','2','3','4','5','6','7','8','9','10','11','12'];

 private platSD60:any =
 {
  0:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0},
  1:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0},
  2:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0},
  3:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0},
  4:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0},
  5:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0},
  6:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0},
  7:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0},
  8:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0},
  9:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0},
  10:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0},
  11:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0},
};

private muelleSD60:string[]=['Muelle 3','Muelle 4','Muelle 5','Muelle 6','Muelle 7','Muelle 8','Muelle 9','Mue 10','Mue 11','Mue 12','Mue 13','Mue 14'];

 //private muelleSD60:string[]=['1','2','3','4','5','6','7','8','9','10','11','12'];
 
 
 //Plataforma Barranquilla

 private platLV122:any =
 {
 0:{0:0,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:2,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:2,25:2,26:2,27:2,28:0,29:0,30:0,31:0,32:0,33:0,34:0,35:0,36:0},
 1:{0:0,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:2,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:2,25:2,26:2,27:2,28:0,29:0,30:0,31:0,32:0,33:0,34:0,35:0,36:0},
 2:{0:0,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:2,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:2,25:2,26:2,27:2,28:0,29:0,30:0,31:0,32:0,33:0,34:0,35:0,36:0},
 3:{0:0,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:2,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:2,25:2,26:2,27:2,28:0,29:0,30:0,31:0,32:0,33:0,34:0,35:0,36:0},
 4:{0:2,1:2,2:2,3:2,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:2,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:2,25:2,26:2,27:2,28:0,29:0,30:0,31:0,32:0,33:0,34:0,35:0,36:0}
};

 private muelleLV122:string[]=['Muelle 1','Muelle 2','Muelle 3','Muelle 4','Muelle 5'];

 private platSD122:any =
 {
 0:{0:0,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:2,11:0,12:0,13:0,14:2,15:2,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0},
 1:{0:0,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:2,11:0,12:0,13:0,14:2,15:2,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0},
 2:{0:1,1:1,2:1,3:1,4:0,5:0,6:0,7:0,8:0,9:0,10:2,11:0,12:0,13:0,14:2,15:2,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0},
 3:{0:1,1:1,2:1,3:1,4:1,5:1,6:1,7:1,8:0,9:0,10:2,11:0,12:0,13:0,14:2,15:2,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0},
 4:{0:1,1:1,2:1,3:1,4:1,5:1,6:1,7:1,8:0,9:0,10:2,11:0,12:0,13:0,14:2,15:2,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0}
};

 private muelleSD122:string[]=['Muelle 1','Muelle 2','Muelle 3','Muelle 4','Muelle 5'];

 //Plataforma Medellin

 private platLV93:any =
 {
 0:{0:2,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:2,29:2,30:2,31:2,32:0,33:0,34:0,35:0,36:0},
 1:{0:2,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:2,29:2,30:2,31:2,32:0,33:0,34:0,35:0,36:0},
 2:{0:2,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:2,29:2,30:2,31:2,32:0,33:0,34:0,35:0,36:0},
 3:{0:2,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:2,29:2,30:2,31:2,32:0,33:0,34:0,35:0,36:0}
};

private muelleLV93:string[]=['Muelle 1','Muelle 2','Muelle 3','Muelle 4'];

private platSD93:any =
 {
 0:{0:2,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:2,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0},
 1:{0:2,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:2,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0},
 2:{0:2,1:1,2:1,3:1,4:0,5:0,6:0,7:0,8:0,9:0,10:2,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0},
 3:{0:2,1:1,2:1,3:1,4:1,5:1,6:1,7:1,8:0,9:0,10:2,11:0,12:2,13:2,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0}
};

private muelleSD93:string[]=['Muelle 1','Muelle 2','Muelle 3','Muelle 4'];


 //Plataforma Cali

 private platLV95:any =
 {
 0:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:2,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:2,25:2,26:2,27:2,28:0,29:0,30:0,31:0,32:0,33:0,34:0,35:0,36:0,37:0},
 1:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:2,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:2,25:2,26:2,27:2,28:0,29:0,30:0,31:0,32:0,33:0,34:0,35:0,36:0,37:0},
 2:{0:2,1:2,2:2,3:2,4:2,5:2,6:2,7:2,8:2,9:2,10:2,11:2,12:2,13:2,14:2,15:2,16:2,17:2,18:2,19:2,20:2,21:2,22:2,23:2,24:2,25:2,26:2,27:2,28:0,29:0,30:0,31:0,32:0,33:0,34:0,35:0,36:0,37:0},
 3:{0:2,1:2,2:2,3:2,4:2,5:2,6:2,7:2,8:0,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:2,25:2,26:2,27:2,28:2,29:2,30:2,31:2,32:2,33:2,34:2,35:2,36:2,37:2}
};

private muelleLV95:string[]=['Muelle 1','Muelle 2','Muelle 3','Muelle 4'];

private platSD95:any =
 {
 0:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:2,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0},
 1:{0:2,1:2,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:2,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0}
};

private muelleSD95:string[]=['Muelle 1','Muelle 2'];

 //Plataforma BUCARAMANGA MANO
private platLV127:any =
{
0:{0:0,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:2,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:2,25:2,26:2,27:2,28:0,29:0,30:0,31:0,32:0,33:0,34:0,35:0,36:2,37:0},
1:{0:0,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:2,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:2,25:2,26:2,27:2,28:0,29:0,30:0,31:0,32:0,33:0,34:0,35:0,36:2,37:0},
2:{0:0,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:2,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:2,25:2,26:2,27:2,28:0,29:0,30:0,31:0,32:0,33:0,34:0,35:0,36:2,37:0}
};

private muelleLV127:string[]=['Muelle 1','Muelle 2','Muelle 3'];

private platSD127:any =
{
0:{0:0,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:2,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0},
1:{0:0,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:2,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0},
2:{0:0,1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:2,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0}
};

private muelleSD127:string[]=['Muelle 1','Muelle 2','Muelle 3'];


  constructor() { }

getMuelle(plat:number,diaSem:string){
  let platDiaSem :string = 'plat' + diaSem + plat;
  switch (platDiaSem) {
    case 'platLV60':
        return this.platLV60;
      break;
    case 'platSD60':
        return this.platSD60;
      break;
    case 'platLV122':
        return this.platLV122;
      break;
    case 'platSD122':
        return this.platSD122;
      break;
      case 'platLV93':
        return this.platLV93;
      break;
    case 'platSD93':
        return this.platSD93;
      break;
      case 'platLV95':
        return this.platLV95;
      break;
    case 'platSD95':
        return this.platSD95;
      break;
      case 'platLV127':
        return this.platLV127;
      break;
    case 'platSD127':
        return this.platSD127;
      break;
    default:
  }
}
getNombreMuelle(plat:number,diaSem:string){
  let muelleDiaSem :string = 'muelle' + diaSem + plat;
  switch (muelleDiaSem) {
    case 'muelleLV60':
        return this.muelleLV60;
      break;
    case 'muelleSD60':
        return this.muelleSD60;
      break;
    case 'muelleLV122':
        return this.muelleLV122;
      break;
    case 'muelleSD122':
        return this.muelleSD122;
      break;
      case 'muelleLV93':
        return this.muelleLV93;
      break;
    case 'muelleSD93':
        return this.muelleSD93;
      break;
      case 'muelleLV95':
        return this.muelleLV95;
      break;
    case 'muelleSD95':
        return this.muelleSD95;
      break;
      case 'muelleLV127':
        return this.muelleLV127;
      break;
    case 'muelleSD127':
        return this.muelleSD127;
      break;
    default:
  }
}
  
}
