import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { AdminUsuService } from '../../services/admin-usu.service';


@Component({
  selector: 'app-cambiopass',
  templateUrl: './cambiopass.component.html',
  styleUrls: ['./cambiopass.component.css']
})
export class CambiopassComponent implements OnInit {

forma:FormGroup;
enviado:boolean=false;

  constructor(public adminUsuService: AdminUsuService,private router: Router) {
      this.forma=new FormGroup({
        'email':new FormControl('',Validators.required)
      });

     }
  
     cambiarPass() { 
      
      this.adminUsuService.resetPasswordInit(this.forma.controls.email.value); 
        }

  ngOnInit() {
  }

}
