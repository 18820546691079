import { Component, OnInit } from '@angular/core';
import {FormGroup, Validators,AbstractControl,FormBuilder, FormArray} from '@angular/forms';

@Component({
  selector: 'app-ver-param',
  templateUrl: './ver-param.component.html',
  styleUrls: ['./ver-param.component.css']
})
export class VerParamComponent implements OnInit {
  forma:FormGroup;
  form:FormGroup;
  private diaCorto:string[]=['L','M','X','J','V','S'];
  proveedorJson1=[];
  constructor(private fb: FormBuilder) {

    let usuario1 = (sessionStorage.getItem('currentUser'));
    let proveedorJson=[];
    proveedorJson=JSON.parse(usuario1);
    this.proveedorJson1=JSON.parse(usuario1);
    console.log(proveedorJson);

  
    this.forma=this.fb.group({   
      paramBog:this.fb.array([{value:proveedorJson['paramBog'][0],disabled: true},{value:proveedorJson['paramBog'][1],disabled: true},{value:proveedorJson['paramBog'][2],disabled: true},{value:proveedorJson['paramBog'][3],disabled: true},{value:proveedorJson['paramBog'][4],disabled: true},{value:proveedorJson['paramBog'][5],disabled: true}]),
      paramMed:this.fb.array([{value:proveedorJson['paramMed'][0],disabled: true},{value:proveedorJson['paramMed'][1],disabled: true},{value:proveedorJson['paramMed'][2],disabled: true},{value:proveedorJson['paramMed'][3],disabled: true},{value:proveedorJson['paramMed'][4],disabled: true},{value:proveedorJson['paramMed'][5],disabled: true}]),
      paramCal:this.fb.array([{value:proveedorJson['paramCal'][0],disabled: true},{value:proveedorJson['paramCal'][1],disabled: true},{value:proveedorJson['paramCal'][2],disabled: true},{value:proveedorJson['paramCal'][3],disabled: true},{value:proveedorJson['paramCal'][4],disabled: true},{value:proveedorJson['paramCal'][5],disabled: true}]),
      paramBuc:this.fb.array([{value:proveedorJson['paramBuc'][0],disabled: true},{value:proveedorJson['paramBuc'][1],disabled: true},{value:proveedorJson['paramBuc'][2],disabled: true},{value:proveedorJson['paramBuc'][3],disabled: true},{value:proveedorJson['paramBuc'][4],disabled: true},{value:proveedorJson['paramBuc'][5],disabled: true}]),
      paramBll:this.fb.array([{value:proveedorJson['paramBll'][0],disabled: true},{value:proveedorJson['paramBll'][1],disabled: true},{value:proveedorJson['paramBll'][2],disabled: true},{value:proveedorJson['paramBll'][3],disabled: true},{value:proveedorJson['paramBll'][4],disabled: true},{value:proveedorJson['paramBll'][5],disabled: true}]),

    });

   }

   get paramBogota(){
    return this.forma.get('paramBog') as FormArray;}
  get paramMedellin(){
      return this.forma.get('paramBog') as FormArray;}
  get paramCali(){
      return this.forma.get('paramBog') as FormArray;}
  get paramBuca(){
      return this.forma.get('paramBog') as FormArray;}
  get paramBarra(){
      return this.forma.get('paramBog') as FormArray;}

  ngOnInit() {
  }

}
