import { Component,OnInit} from '@angular/core';
import {ServiceService} from '../../services/service.service';
import {AdminUsuService} from '../../services/admin-usu.service';
import {FormGroup, Validators,AbstractControl,FormBuilder, FormArray} from '@angular/forms';
import 'rxjs/Rx';
import {Usuario} from '../../interfaces/usuario.interface';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html'
})
export class RegistroComponent implements OnInit {

  forma:FormGroup;
  heroes:any;
  listaUsuariosRevision:any;
  validacionB=false;      
  private dia:string[]=['Lunes','Martes','Miercoles','Jueves','Viernes','Sabado'];
  private diaCorto:string[]=['L','M','X','J','V','S'];
  usuario:Usuario={ 
    nombre:"",
    apellido:"",
    email:"",
    password:"",
    empresa:"",
    observaciones:""
  }

  correos: any;


  constructor(private bdService:ServiceService, 
              private auth:AdminUsuService,private fb: FormBuilder) {

    this.crearFormulario();
    console.log(this.forma.value);
    this.forma.reset({
      nombre: "",
      email: "",
      apellido: "",
      password: "",
      password2: "",
      empresa: "",
      productividad: "",
      observaciones: ""
    });
  } //fin del constructor


get nombreNoValido(){
  return this.forma.get('nombre').invalid && this.forma.get('nombre').touched}

get pass1NoValido(){
  return this.forma.get('password').invalid && this.forma.get('password').touched}

get pass2NoValido(){
  const pass1=this.forma.get('password').value;
  const pass2=this.forma.get('password2').value;
  return (pass1===pass2) ? false  : true;}

get paramBogota(){
  return this.forma.get('paramBog') as FormArray;}
get paramMedellin(){
    return this.forma.get('paramBog') as FormArray;}
get paramCali(){
    return this.forma.get('paramBog') as FormArray;}
get paramBuca(){
    return this.forma.get('paramBog') as FormArray;}
get paramBarra(){
    return this.forma.get('paramBog') as FormArray;}

crearFormulario(){
  this.forma=this.fb.group({

    nombre:['',[Validators.required,Validators.minLength(3)]],
    email:['',[Validators.required,
      Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")],this.pequenaFuncion.bind(this)],
    apellido:['',Validators.required],
    password: ['',[Validators.required,Validators.minLength(6)]],
    password2:[''],
    empresa:['',Validators.required],
    productividad:['',Validators.required],
    paramBog:this.fb.array([[''],[''],[''],[''],[''],['']]),
    paramMed:this.fb.array([[''],[''],[''],[''],[''],['']]),
    paramCal:this.fb.array([[''],[''],[''],[''],[''],['']]),
    paramBuc:this.fb.array([[''],[''],[''],[''],[''],['']]),
    paramBll:this.fb.array([[''],[''],[''],[''],[''],['']]),
    observaciones:['']
  });

   } //Fin Crear Formulario

// cargarDataAlFormulario(){
//   this.forma.setValue({
//     nombre: "",
//     email: "",
//     apellido: "",
//     password: "",
//     password2: "",
//     empresa: "",
//     productividad: "",
//     observaciones: ""
//   })}

ngOnInit(){
  // this.cargarDataAlFormulario();
}

  guardarCambios(){
    console.log(this.forma);
    if (this.forma.valid){
    this.usuario=this.forma.value;
    console.log(this.usuario);
    this.auth.signup(this.forma.value.email, this.forma.value.password);
    this.bdService.nuevoUsuario(this.forma.value).then(() => {
        console.log('Documento creado exitósamente!');
      }, (error) => {
        console.log(error);
      });

    console.log(this.forma.value);
    console.log(this.forma);
    this.forma.reset({
        nombre:"",
        apellido:"",
        correo:"",
        observaciones:"",

    });}
    else {
      console.log("Formulario invalido");

    }
    }


pequenaFuncion(control:AbstractControl){
return this.bdService.getCorreo(control.value).first().map((res) =>{
      let otro:any = res.payload.data();
      return otro ? {existe:true}: null ;
      });
}
}
