import { Component, ViewChild  } from '@angular/core';
import {ServiceService} from '../../services/service.service';
import { BarraOperadorComponent} from '../barra-operador/barra-operador.component'
import { Router } from '@angular/router';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: []
})
export class HomeComponent {
 @ViewChild('selector1', /* TODO: add static flag */ null) selector1: BarraOperadorComponent;
  
existing:any;
noticias:string;
mostrarNoticia:boolean=false;

  constructor(private router: Router,private _agendaService:ServiceService) { 

this._agendaService.getNoticias().subscribe((heroe1) =>{
    let flag:any;
    flag= heroe1.payload.data();
    console.log(flag)
    this.noticias=flag.noticias;
    this.mostrarNoticia= this.noticias === "0" ? false:true; 
  });

    // Get the existing data
    var existing1 = sessionStorage.getItem('currentUser');
    this.existing = existing1 ? JSON.parse(existing1) : {};
    console.log(this.existing);

    // If no existing data, create an array
    // Otherwise, convert the sessionStorage string to an array
    //existing = existing ? JSON.parse(existing) : {};
    
    // Add new data to localStorage Array
    //existing['empresa'] = this.forma.value.proveedor;
 
    
    // Save back to localStorage
    //sessionStorage.setItem('currentUser', JSON.stringify(existing));

  }

  cerrarSesion(){
    console.log("entro al home");
  }

}
