import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hora'
})
export class HoraPipe implements PipeTransform {

  transform(value: number, ...args: any[]): any {
    
    if (value){
    let horaString:string;
    let horaNumber:number;
    let horaMin:number;
    let horaMinString:string;


    horaNumber=Math.floor(value*24);

    //console.log(horaNumber);
    horaMin=Math.floor((value*5184000-horaNumber*216000)/3600);
    horaMin = horaMin<0 ? 0:horaMin;
    //console.log(horaMin);
    if (horaMin <=9){
      horaMinString= "0"+horaMin.toString();}
    else {horaMinString=horaMin.toString()}
    horaString=horaNumber.toString()+":"+horaMinString;
    return horaString;
  } else{return "";}
  }
}
