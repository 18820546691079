import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormGroup, Validators,AbstractControl,FormBuilder, FormArray} from '@angular/forms';
import {ServiceService} from '../../../services/service.service';

@Component({
  selector: 'app-dialog-traza',
  templateUrl: './dialog-traza.component.html',
  styleUrls: ['./dialog-traza.component.css']
})
export class DialogTrazaComponent implements OnInit {

  forma:FormGroup;
  registro:any;

  usercollection = 
    { "plataforma": 122,
      "dia": "2021-07-23T05:00:00.000Z",
      "hora_cita": 0.25,
      "proveedor": "Prueba",
      "cajas-informadas": 256,
      "frecuencia": "Si",
      "cajas_reales": 230,
      "hora_llegada": 0.257638888888889,
      "cumplio_cita": "No",
      "observaciones": ""};

  constructor(private dbService:ServiceService, private fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogTrazaComponent>,
    @ Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data);
      this.registro=data;

      this.forma=this.fb.group({
        cajas:['',Validators.required],
        hora_llegada:['',Validators.required],
        hora_inicio_rec:['',Validators.required],
        hora_salida:['',Validators.required],
        observaciones:['']
      });
    }

  ngOnInit() {

  }

  guardar(){

    console.log("LLegada:", this.forma.value.hora_llegada);
    console.log("cita:",this.registro.data.cita);

//hora de cita
    let hora:number;
    let minutos:number;
    let posicion:number;
    posicion=this.registro.data.cita.lastIndexOf(":");
    hora=parseInt(this.registro.data.cita.slice(0,posicion));
    minutos=parseInt(this.registro.data.cita.slice(-2));
    if(hora<5){hora=hora+12};
    console.log("Cita: hora", hora,"  minutos", minutos);
    this.usercollection.hora_cita=(hora*216000+minutos*3600)/5184000;


//hora de llegada
    let hora1:number;
    let minutos1:number;
    let posicion1:number;
    posicion=this.forma.value.hora_llegada.lastIndexOf(":");
    hora1=parseInt(this.forma.value.hora_llegada.slice(0,posicion));
    minutos1=parseInt(this.forma.value.hora_llegada.slice(-2));
    console.log("Llegada: hora", hora1,"  minutos", minutos1);
    this.usercollection.hora_llegada=(hora1*216000+minutos1*3600)/5184000;

//cumplio cita?
    this.usercollection.hora_llegada<=this.usercollection.hora_cita ?
      this.usercollection.cumplio_cita="Si" : this.usercollection.cumplio_cita="No";
    console.log(this.usercollection.cumplio_cita);
  
  (this.registro.data.entregaFuera==="FFProv") ? this.usercollection.frecuencia = "No" : this.usercollection.frecuencia = "Si"; 


    this.usercollection.plataforma=this.registro.data.plataforma;
    this.usercollection.dia=this.registro.data.dia.toString().substring(0,4)+"-"+ 
    this.registro.data.dia.toString().substring(4,6)+"-"+this.registro.data.dia.toString().substring(6,8);
    this.usercollection.proveedor=this.registro.data.empresa;
    this.usercollection['cajas-informadas']=this.registro.data.cajas;
    this.usercollection.cajas_reales=this.forma.value.cajas;
    this.usercollection.observaciones=this.forma.value.observaciones;
    

    console.log(this.usercollection.dia);

    //hora de Recibo
    let hora2:number;
    let minutos2:number;
    let posicion2:number;
    posicion2=this.forma.value.hora_inicio_rec.lastIndexOf(":");
    hora2=parseInt(this.forma.value.hora_inicio_rec.slice(0,posicion));
    minutos2=parseInt(this.forma.value.hora_inicio_rec.slice(-2));
    console.log("Recibo Suppla: hora", hora2,"  minutos", minutos2);

    //cumplio Suppla  XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    var cumplio_suppla:string;
    var hora_recibo:number;
    hora_recibo=(hora2*216000+minutos2*3600)/5184000;

    if (this.usercollection.cumplio_cita==="No"){cumplio_suppla="No Aplica"}else{
      hora_recibo<=(this.usercollection.hora_cita + (30*3600/5184000)) ?
        cumplio_suppla="Si" : cumplio_suppla="No";
    };

    //hora de Salida
    let hora3:number;
    let minutos3:number;
    let posicion3:number;
    posicion3=this.forma.value.hora_llegada.lastIndexOf(":");
    hora3=parseInt(this.forma.value.hora_salida.slice(0,posicion3));
    minutos3=parseInt(this.forma.value.hora_salida.slice(-2));
    var hora_salida:number =(hora3*216000+minutos3*3600)/5184000;

    let indOperador=
    { "plataforma": this.usercollection.plataforma,
    "dia": this.usercollection.dia,
    "hora_cita": this.usercollection.hora_cita,
    "hora_fin": this.registro.data.horaFin,
    "proveedor": this.usercollection.proveedor,
    "cajas_informadas": this.usercollection['cajas-informadas'],
    "frecuencia": this.usercollection.frecuencia,
    "cajas_reales": this.usercollection.cajas_reales,
    "hora_llegada": this.usercollection.hora_llegada,
    "cumplio_cita": this.usercollection.cumplio_cita,
    "observaciones": this.usercollection.observaciones,
    "hora_recibo": hora_recibo,
    "hora_salida": hora_salida,
    "cumplio_suppla": cumplio_suppla,
  };

  // Subir indicador para el proveedor
    this.dbService.subirExcel(this.usercollection.proveedor,this.usercollection.dia 
      + "-" + this.usercollection.plataforma,this.usercollection).then(() => {
      console.log('Documento creado o ajustado exitósamente!');
    }, (error) => {
       console.error(error);
    });

 //Subir indicador para el Operador

        let azCarpeta:string= (this.usercollection.hora_cita*10000).toFixed(0).toString()+"-"+ this.registro.data.muelle.toString();
        this.dbService.indOperador(this.usercollection.plataforma.toString(),
        this.usercollection.dia,{[azCarpeta]:indOperador}).then(() => {
          console.log('Documento creado o ajustado exitósamente!');
            }, (error) => {
            console.error(error);
                this.dbService.crearCarpeta(this.usercollection.plataforma.toString(),
                this.usercollection.dia,{nada:{nada1:"101",nada:"2"}}).then(() => {
                console.log('Documento creado o ajustado exitósamente!');
                    this.dbService.indOperador(this.usercollection.plataforma.toString(),
                    this.usercollection.dia,{[azCarpeta]:indOperador}).then(() => {
                          this.dbService.borrarIndOperador(this.usercollection.plataforma.toString(),
                          this.usercollection.dia).then(() => {
                            console.log('Documento creado o ajustado exitósamente!');
                              }, (error) => {
                              console.error(error);
                              });
                      console.log('Documento creado o ajustado exitósamente!');
                        }, (error) => {
                        console.error(error);
                        });
                }, (error) => {
                console.error(error);
                });
            });

    this.dialogRef.close(this.registro.indice);
    //save de document
  }

  cancelar(){
    this.dialogRef.close("cancelado");
  }

 nollego(){

//hora de cita
let hora:number;
let minutos:number;
let posicion:number;
posicion=this.registro.data.cita.lastIndexOf(":");
hora=parseInt(this.registro.data.cita.slice(0,posicion));
minutos=parseInt(this.registro.data.cita.slice(-2));
if(hora<5){hora=hora+12};
console.log("Cita: hora", hora,"  minutos", minutos);
this.usercollection.hora_cita=(hora*216000+minutos*3600)/5184000;

this.usercollection.hora_llegada=0,9999;


  this.usercollection.plataforma=this.registro.data.plataforma;
  this.usercollection.dia=this.registro.data.dia.toString().substring(0,4)+"-"+ 
  this.registro.data.dia.toString().substring(4,6)+"-"+this.registro.data.dia.toString().substring(6,8);
  this.usercollection.proveedor=this.registro.data.empresa;
  this.usercollection['cajas-informadas']=this.registro.data.cajas;
  this.usercollection.frecuencia = "No"
  
  this.usercollection.cajas_reales=0;
  this.usercollection.observaciones="No llego";
  this.usercollection.cumplio_cita="No";
  
//Subir indicador Proveedor
  this.dbService.subirExcel(this.usercollection.proveedor,this.usercollection.dia + "-" + this.usercollection.plataforma,this.usercollection).then(() => {
    console.log('Documento creado o ajustado exitósamente!');
  }, (error) => {
     console.error(error);
  });

  //Subir indicador del operador
  let indOperador=
  { "plataforma": this.usercollection.plataforma,
  "dia": this.usercollection.dia,
  "hora_cita": this.usercollection.hora_cita,
  "hora_fin": 0,
  "proveedor": this.usercollection.proveedor,
  "cajas_informadas": this.usercollection['cajas-informadas'],
  "frecuencia": this.usercollection.frecuencia,
  "cajas_reales": 0,
  "hora_llegada": 0,
  "cumplio_cita": "No",
  "observaciones": "No llego",
  "hora_recibo": 0,
  "hora_salida": 0,
  "cumplio_suppla": "No aplica",
};

let azCarpeta:string= (this.usercollection.hora_cita*10000).toFixed(0).toString()+"-"+ this.registro.data.muelle.toString();
this.dbService.indOperador(this.usercollection.plataforma.toString(),
this.usercollection.dia,{[azCarpeta]:indOperador}).then(() => {
  console.log('Documento creado o ajustado exitósamente!');
    }, (error) => {
    console.error(error);
        this.dbService.crearCarpeta(this.usercollection.plataforma.toString(),
        this.usercollection.dia,{nada:{nada1:"101",nada:"2"}}).then(() => {
        console.log('Documento creado o ajustado exitósamente!');
            this.dbService.indOperador(this.usercollection.plataforma.toString(),
            this.usercollection.dia,{[azCarpeta]:indOperador}).then(() => {
                  this.dbService.borrarIndOperador(this.usercollection.plataforma.toString(),
                  this.usercollection.dia).then(() => {
                    console.log('Documento creado o ajustado exitósamente!');
                      }, (error) => {
                      console.error(error);
                      });
              console.log('Documento creado o ajustado exitósamente!');
                }, (error) => {
                console.error(error);
                });
        }, (error) => {
        console.error(error);
        });
    });

    this.dialogRef.close(this.registro.indice);
  }

}
