import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plataforma'
})
export class PlataformaPipe implements PipeTransform {

  transform(value: number, ...args: any[]): any {
    
    switch (value) {
      case 60: return 'Plataforma Bogota';
      case 122: return 'Plataforma Barranquilla';
      case 93: return 'Plataforma Medellin';
      case 95: return 'Plataforma Cali';
      case 127: return 'Plataforma Bucaramanga';  
      default: return ''   
    }

  }

}
