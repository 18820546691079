// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
  apiKey: "AIzaSyA_feXdFgeVx9QrRoWH12CIbalJnDyLKjc",
  authDomain: "agendamiento-36adf.firebaseapp.com",
  databaseURL: "https://agendamiento-36adf.firebaseio.com",
  projectId: "agendamiento-36adf",
  storageBucket: "",
  messagingSenderId: "875280889885",
  appId: "1:875280889885:web:978663d7120f9510"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
