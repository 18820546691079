import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl, Validators,AbstractControl} from '@angular/forms';
import { Router } from '@angular/router';
import {ServiceService} from '../../services/service.service';
@Component({
  selector: 'app-sin-cita',
  templateUrl: './sin-cita.component.html',
  styleUrls: ['./sin-cita.component.css']
})
export class SinCitaComponent implements OnInit {
  forma:FormGroup;
  invalido:boolean=false;
  usuarios:any[]=[];
  usuariosTem:any[]=[];
  cargando=true;
  proveedores:any[]=[];
  minDate=new Date();
  maxDate=new Date();
  dia:string;

  usercollection = 
  { "plataforma": 122,
    "dia": "2021-07-23T05:00:00.000Z",
    "proveedor": "Prueba",
    "cajas_reales": 230,
    "hora_llegada": 0.257638888888889,
    "cumplio_cita": "Sin Cita",
    "frecuencia": "N.A."  ,
    "observaciones": "Sin Cita",
    "cumplio_suppla": "si"};
  
    constructor(private router: Router,private dbService:ServiceService) { 

          //poner limites de fecha:
  this.minDate.setDate(this.minDate.getDate()-6);
  this.maxDate.setDate(this.maxDate.getDate());


      this.forma=new FormGroup({
        'plataforma':new FormControl('',[Validators.required]),
        'proveedor':new FormControl('',[Validators.required]),
        'fecha':new FormControl('',[Validators.required]),
        'hora_ini_rec':new FormControl('',[Validators.required]),
        'hora_salida':new FormControl('',[Validators.required]),
        'motivo':new FormControl('',[Validators.required]),
        'nCajas':new FormControl('',[Validators.required,Validators.pattern("[0-9]+")]),

      });
  
    }
  
    ngOnInit() {
          //traer los proveedores
          this.dbService.usuarios().subscribe((catsSnapshot) => 
          {catsSnapshot.forEach((catData: any) => {
          this.usuarios.push({
            id: catData.payload.doc.id,
            data: catData.payload.doc.data()
          });      
          });
          this.cargando=false;
          if (this.usuarios){this.proveedores=this.usuarios}
          if (this.proveedores){
            // console.log(this.proveedores)
           this.proveedores.sort(function (a, b) {
             if (a.data.empresa> b.data.empresa) {
               return 1;
             }
             if (a.data.empresa < b.data.empresa) {
               return -1;
             }
             // a must be equal to b
             return 0;
           });
          //  console.log(this.proveedores);
         } 
    
           },()=>{},()=>{console.log("Se completo")});
        }




    guardar() {
    
    //hora de Recibo
    let hora2:number;
    let minutos2:number;
    let posicion2:number;
    posicion2=this.forma.value.hora_ini_rec.lastIndexOf(":");
    hora2=parseInt(this.forma.value.hora_ini_rec.slice(0,posicion2));
    minutos2=parseInt(this.forma.value.hora_ini_rec.slice(-2));
    console.log("Recibo Suppla: hora", hora2,"  minutos", minutos2);
    var hora_recibo:number;
    hora_recibo=(hora2*216000+minutos2*3600)/5184000;

    var diaNumber:number=this.forma.value.fecha.getFullYear()*10000+
    (this.forma.value.fecha.getMonth()+1)*100+this.forma.value.fecha.getDate();
    this.dia=diaNumber.toString().substring(0,4)+"-"+ 
    diaNumber.toString().substring(4,6)+"-"+diaNumber.toString().substring(6,8);

      this.usercollection.plataforma=parseInt(this.forma.value.plataforma);
      this.usercollection.dia=this.dia;
      this.usercollection.proveedor=this.forma.value.proveedor;
      this.usercollection.cajas_reales=this.forma.value.nCajas;

    console.log(this.usercollection);

        //Subir indicador para el proveedor
    this.dbService.subirExcel(this.usercollection.proveedor,this.dia 
      + "-" + this.usercollection.plataforma.toString(),this.usercollection).then(() => {
      console.log('Documento creado o ajustado exitósamente!');
    }, (error) => {
       console.error(error);
    });

    var nuevoNomProv:string=this.forma.value.proveedor;
    nuevoNomProv=nuevoNomProv.replace(/\./g,'');
    nuevoNomProv=nuevoNomProv.replace(/ /g,'');
    console.log(this.forma.value.proveedor);
    console.log(nuevoNomProv);
     //Subir indicador para el Operador

  
     this.dbService.indOperador(this.usercollection.plataforma.toString(),
     this.usercollection.dia,{[nuevoNomProv]:this.usercollection}).then(() => {
       console.log('Documento creado o ajustado exitósamente!');
         }, (error) => {
         console.error(error);
             this.dbService.crearCarpeta(this.usercollection.plataforma.toString(),
             this.usercollection.dia,{nada:{nada1:"101",nada:"2"}}).then(() => {
             console.log('Documento creado o ajustado exitósamente!');
                 this.dbService.indOperador(this.usercollection.plataforma.toString(),
                 this.usercollection.dia,{[nuevoNomProv]:this.usercollection}).then(() => {
                       this.dbService.borrarIndOperador(this.usercollection.plataforma.toString(),
                       this.usercollection.dia).then(() => {
                         console.log('Documento creado o ajustado exitósamente!');
                           }, (error) => {
                           console.error(error);
                           });
                   console.log('Documento creado o ajustado exitósamente!');
                     }, (error) => {
                     console.error(error);
                     });
             }, (error) => {
             console.error(error);
             });
         });
    this.router.navigateByUrl('/home-operador');
    }
  
  borrar(){

    var nuevoNomProv:string=this.forma.value.proveedor;
    nuevoNomProv=nuevoNomProv.replace(/\./g,'');
    nuevoNomProv=nuevoNomProv.replace(/ /g,'');
    console.log(this.forma.value.proveedor);
    console.log(nuevoNomProv);

    var diaNumber:number=this.forma.value.fecha.getFullYear()*10000+
    (this.forma.value.fecha.getMonth()+1)*100+this.forma.value.fecha.getDate();
    this.dia=diaNumber.toString().substring(0,4)+"-"+ 
    diaNumber.toString().substring(4,6)+"-"+diaNumber.toString().substring(6,8);
    this.usercollection.plataforma=parseInt(this.forma.value.plataforma);

    this.dbService.borrarIndOperador1(this.usercollection.plataforma.toString(),
    this.dia,nuevoNomProv).then(() => {
      console.log('Documento creado o ajustado exitósamente!');
        }, (error) => {
        console.error("error");
        });
  this.router.navigateByUrl('/home-operador');
  }
  }
  