import { Component, OnInit } from '@angular/core';
import {ServiceService} from '../../services/service.service';
import {FormGroup, FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-seg-operador',
  templateUrl: './seg-operador.component.html',
  styleUrls: ['./seg-operador.component.css']
})
export class SegOperadorComponent implements OnInit {
minDate=new Date();
maxDate=new Date();
dia:string;
otro:any;
citas:any[]=[];
forma:FormGroup;
codPlataforma:number;
indicadorCita:number;
indicadorOntime:number;
indicadorCumpleOp:number;
indicadorCajas:number;
mostrarIndicadorFlag:boolean = false;
dateControl:FormControl;
cajasTotales:number=0;

  constructor(private dbService:ServiceService) { 
    this.minDate.setDate(this.minDate.getDate()-6);
    this.maxDate.setDate(this.maxDate.getDate());
    
    this.forma=new FormGroup({
      'plataforma':new FormControl('',[Validators.required])
    });
    
    this.dateControl = new FormControl('',[Validators.required]);

    }


mostrarInd(){
  this.dia=this.dateControl.value.getFullYear()*10000+
  (this.dateControl.value.getMonth()+1)*100+this.dateControl.value.getDate();
  this.dia=this.dia.toString().substring(0,4)+"-"+ 
    this.dia.toString().substring(4,6)+"-"+this.dia.toString().substring(6,8);

  console.log(this.dia,this.forma.value.plataforma);
  this.mostrarIndicadorFlag= true;
  this.codPlataforma=parseInt(this.forma.value.plataforma);


  this.dbService.getseguimientoOperador(this.forma.value.plataforma,this.dia).subscribe((heroe1) =>{
    this.otro = heroe1.payload.data();
    console.log(this.otro);
    if (this.otro) {
      this.citas=Object.values(this.otro);

                if (this.citas){
                  console.log('entró aqui');
                  console.log(this.citas.length);
                  console.log(this.citas);
              
                  // var acumuladoConCitas:number=0;
                  var acumuladoOntime:number=0;
                  var acumuladoCumpleOp:number=0; 
                  var acumuladoCumpleOpNom:number=0; 
              
                  var indAcuCajas:number[]=[];
                  var totalCajasInformadas:number=0;

            for (let i:number=0;i<this.citas.length;i++){
              
                  // acumuladoConCitas = (this.citas[i].cumplio_cita.toLowerCase().trim() != "si") ? acumuladoConCitas+1:acumuladoConCitas;
                  acumuladoOntime = (this.citas[i].cumplio_cita.toLowerCase().trim() == "si") ? acumuladoOntime+1:acumuladoOntime;
                  if(this.citas[i].cumplio_suppla.toLowerCase().trim() == "si"){
                      acumuladoCumpleOp=acumuladoCumpleOp+1;
                      acumuladoCumpleOpNom ++}else{if((this.citas[i].cumplio_suppla.toLowerCase().trim() == "no")){
                        acumuladoCumpleOpNom ++ }};
                  
                  // let cajasReales:number=this.citas[i].cajas_reales;
                  // let cajasInformadas:number=this.citas[i]['cajas_informadas'];
                  // let dispersion:number;
                  this.cajasTotales+=this.citas[i].cajas_reales;
                  if (this.citas[i].cajas_informadas){            
                  totalCajasInformadas+=this.citas[i].cajas_informadas;}
                  console.log(this.citas[i].cajas_reales, this.citas[i]['cajas_informadas']);
                  // if (this.citas[i].cajas_reales>=0 && this.citas[i]['cajas_informadas']>=0){
                  //     dispersion = Math.abs(cajasReales - cajasInformadas)/cajasInformadas;
                  //     console.log('entro', this.citas[i]['cajas_informadas']);
                  //     indAcuCajas.push(dispersion);} else 
                  //     {console.log('espacios en blanco')
                  // }
                  
            } //Fin del For 
            
            // CALCULO DE INDICADORES
            // this.indicadorCita=Math.round((this.citas.length-acumuladoConCitas)/(this.citas.length)*1000)/1000
            this.indicadorOntime=Math.round(acumuladoOntime/(this.citas.length)*1000)/1000
            this.indicadorCumpleOp=Math.round(acumuladoCumpleOp/(acumuladoCumpleOpNom)*1000)/1000;
            console.log(indAcuCajas);
          
        
            // let suma:number = 0;
            // indAcuCajas.forEach (function(numero){suma += numero;});
            console.log(this.cajasTotales,totalCajasInformadas);
            
            this.indicadorCajas=(Math.round(this.cajasTotales/totalCajasInformadas*1000)/1000);
              
                };}
  });
    
  
    }

  ngOnInit() {
  }

}
