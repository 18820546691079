import { Component, OnInit,OnDestroy  } from '@angular/core';
import {ServiceService} from '../../services/service.service';
import {FormGroup, FormControl, Validators} from '@angular/forms';


@Component({
  selector: 'app-indicadores',
  templateUrl: './indicadores.component.html',
  styleUrls: ['./indicadores.component.css']
})
export class IndicadoresComponent implements OnInit {
citas:any[]=[];
forma:FormGroup;

codPlataforma:number;
indicadorCita:number;
indicadorOntime:number;
indicadorFrecuencia:number;
indicadorCajas:number;
mostrarIndicadorFlag:boolean = false;

  constructor(private dbService:ServiceService) { 

    this.forma=new FormGroup({
      'plataforma':new FormControl('',[Validators.required])
    });
  }

  mostrarInd(){
    this.codPlataforma=parseInt(this.forma.value.plataforma);
    this.mostrarIndicadorFlag= true;
    let proveedor = (sessionStorage.getItem('currentUser'));
    let proveedorJson:any = JSON.parse(proveedor);
    var proveedorIndicador:string = proveedorJson.empresa;
  
    this.dbService.getIndicadores(proveedorIndicador,parseInt(this.forma.value.plataforma)).
    subscribe((catsSnapshot) => {catsSnapshot.forEach((catData: any) => {
      this.citas.push({
        id: catData.payload.doc.id,
        data: catData.payload.doc.data()
      });
    });
    
  
    if (this.citas){
      console.log('entró aqui');
      console.log(this.citas.length);
      console.log(this.citas);
  
      var acumuladoConCitas:number=0;
      var acumuladoOntime:number=0;
      var acumuladoFrecuencia:number=0; 
  
      var indAcuCajas:number[]=[];
  
    for (let i:number=0;i<this.citas.length;i++){
  
      acumuladoConCitas = (this.citas[i].data.cumplio_cita.toLowerCase().trim() != "si") ? acumuladoConCitas+1:acumuladoConCitas;
      acumuladoOntime = (this.citas[i].data.cumplio_cita.toLowerCase().trim() == "si") ? acumuladoOntime+1:acumuladoOntime;
      acumuladoFrecuencia = this.citas[i].data.frecuencia.toLowerCase().trim() == "si" ? acumuladoFrecuencia+1:acumuladoFrecuencia;
   
      let cajasReales:number=this.citas[i].data.cajas_reales;
      let cajasInformadas:number=this.citas[i].data['cajas-informadas'];
      let dispersion:number;

      if (this.citas[i].data.cajas_reales && this.citas[i].data['cajas-informadas']){
      dispersion = Math.abs(cajasReales - cajasInformadas)/cajasInformadas;
      console.log('entro', this.citas[i].data['cajas-informadas']);
      indAcuCajas.push(dispersion);} else {console.log('espacios en blanco')}
       
    } 
      this.indicadorCita=Math.round((this.citas.length-acumuladoConCitas)/(this.citas.length)*1000)/1000
      this.indicadorOntime=Math.round(acumuladoOntime/(this.citas.length)*1000)/1000
      this.indicadorFrecuencia=Math.round(acumuladoFrecuencia/(this.citas.length)*1000)/1000;
      console.log(indAcuCajas);
  
      let suma:number = 0;
      indAcuCajas.forEach (function(numero){suma += numero;});
      console.log(suma);
      this.indicadorCajas=1-(Math.round(suma/indAcuCajas.length*1000)/1000);
   
    };
  
  }
  );
  

  }

  ngOnInit() {

    this.forma.controls['plataforma'].valueChanges.subscribe(value => {
      console.log(value);
      this.mostrarIndicadorFlag= false;
      this.citas=[];
    });
  }

  ngOnDestroy(){
    if (this.citas.length>=15){
    for (let i:number=0; i<this.citas.length-15;i++) {
      let diaPla:string;
      diaPla= this.citas[i].data.dia.substr(0,10) + "-" + this.citas[i].data.plataforma;
      console.log('dia', diaPla);
      this.dbService.borrarIndicador(this.citas[i].data.proveedor,diaPla)
      .then(() => {
        console.log('Documento creado o ajustado exitósamente!');
      }, (error) => {
         console.error(error);
      });
  
     }// Fin del For
     
    }// Fin del If

  } // Fin del ngOnDestroy

}