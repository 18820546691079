import { Component, OnInit } from '@angular/core';
import {DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ayuda',
  templateUrl: './ayuda.component.html',
  styleUrls: ['./ayuda.component.css']
})
export class AyudaComponent implements OnInit {

  safeUrl;
  constructor(private _satinizer:DomSanitizer) { }

  ngOnInit() {
  
  //this.safeUrl=this._satinizer.bypassSecurityTrustResourceUrl('https://youtu.be/scUnP0ykIYk');
  }
  getVideoIframe(url) {
    var video, results;
 
    if (url === null) {
        return '';
    }
    results = url.match('[\\?&]v=([^&#]*)');
    video   = (results === null) ? url : results[1];
 
    return this._satinizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video);   
}


}
