import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { AdminUsuService } from '../../services/admin-usu.service';
import {ServiceService} from '../../services/service.service';

@Component({
  selector: 'app-login-auth',
  templateUrl: './login-auth.component.html',
  styleUrls: ['./login-auth.component.css']
})
export class LoginAuthComponent implements OnInit {

forma:FormGroup;

  constructor(public adminUsuService: AdminUsuService,private router: Router,
    private dbService:ServiceService) {

    this.forma=new FormGroup({
      'email':new FormControl('',Validators.required),
      'password':new FormControl('',Validators.required)
    });

   }

  ngOnInit() {
  }

  cambiarPass() { 
  this.adminUsuService.resetPasswordInit(this.forma.controls.email.value.toLowerCase().trim()); 
    }
    
  ingresar(){
    this.adminUsuService.login(this.forma.controls.email.value.toLowerCase().trim(),
      this.forma.controls.password.value);
  }

  salir() {
    this.adminUsuService.logout();
  }

  // navigate() {
  //   let usuario = (sessionStorage.getItem('currentUser'));
  //   let proveedorJson=[];
  //   proveedorJson=JSON.parse(usuario);
  //   let role:string = proveedorJson['role'];
  //   console.log(role);
  //   if(!role){
  //     console.log('si entra al if');
  //   this.router.navigateByUrl('/agenda');
  // } else{
  //   this.router.navigateByUrl('/admin');
  // }
  // }

}
