import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'horaMil'
})
export class HoraMilPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {


    if (value){
      var hora:string[]=[
        '6:00','6:15','6:30','6:45',
        '7:00','7:15','7:30','7:45',
        '8:00','8:15','8:30','8:45',
        '9:00','9:15','9:30','9:45',
        '10:00','10:15','10:30','10:45',
        '11:00','11:15','11:30','11:45',
        '12:00','12:15','12:30','12:45',
        '1:00','1:15','1:30','1:45',
        '2:00','2:15','2:30','2:45',
        '3:00','3:15','3:30','3:45',
        '4:00','4:15','4:30','4:45',
        '5:00','5:15','5:30','5:45'];

        var horaMilitar:string[]=[
          '6:00','6:15','6:30','6:45',
          '7:00','7:15','7:30','7:45',
          '8:00','8:15','8:30','8:45',
          '9:00','9:15','9:30','9:45',
          '10:00','10:15','10:30','10:45',
          '11:00','11:15','11:30','11:45',
          '12:00','12:15','12:30','12:45',
          '13:00','13:15','13:30','13:45',
          '14:00','14:15','14:30','14:45',
          '15:00','15:15','15:30','15:45',
          '16:00','16:15','16:30','16:45',
          '17:00','17:15','17:30','17:45'];

        for (let i=0; i<=47;i++){
          if(value==hora[i]){
             return horaMilitar[i];}}
        
    } else{return "";}
    }
}
