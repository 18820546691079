import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contactos',
  templateUrl: './contactos.component.html',
  styleUrls: ['./contactos.component.css']
})
export class ContactosComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  home(){
    let usuario = (sessionStorage.getItem('currentUser'));
    let proveedorJson=[];
     proveedorJson=JSON.parse(usuario);
     var role:string = proveedorJson['role'];
     if (proveedorJson['empresa']!="0" ){
      this.router.navigateByUrl('/home')}
      if (role==='consulta'){
        this.router.navigateByUrl('home-consulta')}
        }

}
