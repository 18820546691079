import { Component, OnInit } from '@angular/core';
import {AdminUsuService} from '../../../services/admin-usu.service';

@Component({
  selector: 'app-barra-consulta',
  templateUrl: './barra-consulta.component.html',
  styleUrls: ['./barra-consulta.component.css']
})
export class BarraConsultaComponent implements OnInit {

  constructor(private adminUsuService:AdminUsuService) { }

  ngOnInit() {
  }

  cerrarSesion(){
    console.log("entro a la barra");
    this.adminUsuService.logout();
  }

}
