
import { Component, OnInit,OnDestroy} from '@angular/core';
import {ServiceService} from '../../services/service.service';
import {FormGroup,FormControl,Validators} from '@angular/forms';
import { Router } from '@angular/router';
import {Observable, Subscription} from "rxjs";
import { MatDialog} from '@angular/material';
import {DialogTrazaComponent} from './dialog-traza/dialog-traza.component';

@Component({
  selector: 'app-in-indicador',
  templateUrl: './in-indicador.component.html',
  styleUrls: ['./in-indicador.component.css']
})
export class InIndicadorComponent implements OnInit {
  reportados:number[]=[];
  citas:any[]=[];
  cargando=true;
  minDate=new Date();
  maxDate=new Date();
  dateControl:FormControl;
  forma:FormGroup;
  dia:number;
  mostrar=true;
  sincitas:boolean= false;
  diaMuelles:any;
  proveedorJson:any[]=[];
  heroes:any[]=[];
  otro:any;
  subscription: Subscription;
  subsFlag:boolean=false;

  constructor(private _agendaService:ServiceService,public dialog: MatDialog,private router: Router) { 
  
    //poner limites de fecha:
  this.minDate.setDate(this.minDate.getDate()-6);
  this.maxDate.setDate(this.maxDate.getDate());
  }

  ngOnInit() {
      this.forma=new FormGroup({
        'plataforma':new FormControl('',[Validators.required])
      });
    this.dateControl = new FormControl('',[Validators.required]);
  }

platSeleccionada(){
  this.dia=this.dateControl.value.getFullYear()*10000+
  (this.dateControl.value.getMonth()+1)*100+this.dateControl.value.getDate();
  let plataforma=parseInt(this.forma.value.plataforma);
  console.log(this.dia,plataforma);

  this._agendaService.getCitasDiaPla(this.dia.toString(),plataforma).
    subscribe((catsSnapshot) => {catsSnapshot.forEach((catData: any) => {
      this.citas.push({
        id: catData.payload.doc.id,
        data: catData.payload.doc.data()
      });
    });
    if (this.citas){

//Organizar las citas por hora
      this.citas.sort(function (a, b) {
        let horaCita_a:number;
        let horaCita_b:number;
        let hora:number;
        let minutos:number;
        let posicion:number;
        posicion=a.data.cita.lastIndexOf(":");
        hora=parseInt(a.data.cita.slice(0,posicion));
        minutos=parseInt(a.data.cita.slice(-2));
        if(hora<5){hora=hora+12};
        horaCita_a=(hora*216000+minutos*3600)/5184000;

        posicion=b.data.cita.lastIndexOf(":");
        hora=parseInt(b.data.cita.slice(0,posicion));
        minutos=parseInt(b.data.cita.slice(-2));
        if(hora<5){hora=hora+12};
        horaCita_b=(hora*216000+minutos*3600)/5184000;

        if (horaCita_a> horaCita_b) {
          return 1;
        }
        if (horaCita_a < horaCita_b) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
     //  console.log(this.proveedores);
    }
//Fin de Organizar las citas 

    this.cargando=false;
    this.mostrar = (this.citas.length==0)? true:false;
    this.sincitas= (this.citas.length==0)? true:false;
    console.log(this.citas.length);
    console.log(this.citas);
  });
  }



  registrarCita(i:number){
    this.citas[i].indice=i;
    const dialogo1 = this.dialog.open(DialogTrazaComponent, {
      data: this.citas[i]});
    var resNumber:number;
    dialogo1.afterClosed().subscribe(res => {
      if (res != undefined) {
        resNumber=parseInt(res);
        console.log(res);}
          if (res !="cancelado"){
              this.citas[resNumber].data.reportado="Si";
              this.reportados.push(resNumber);
      }
    });

  } //Fin de registrar cita


  salir(){
    console.log("entro aqui");

  this.router.navigateByUrl('/home-operador');

  }

  ngOnDestroy() {
    for (let i:number=0; i<this.reportados.length;i++) {

      this._agendaService.citaReportada(this.citas[this.reportados[i]].data.dia.toString(),this.citas[this.reportados[i]].id).then(() => {
        console.log('Documento creado o ajustado exitósamente!');
      }, (error) => {
         console.error(error);
      });
  
     }// Fin del For
  }


}
