import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GuardOperadorService implements CanActivate  {

  constructor(private router: Router) { }

canActivate() {
    // If the user is not logged in we'll send them back to the home page
    
    
    if (!this.getUserLoggedAdmin()) {
        console.log('No estás logueado');
        this.router.navigate(['/']);
        return false;
    } else {
    return true;}
}

getUserLoggedAdmin() {
  let usuario = (sessionStorage.getItem('currentUser'));
  let proveedorJson=[];
  proveedorJson=JSON.parse(usuario);
  let role:string = proveedorJson['role'];
  if (role==='operador' || role==='consulta'){
  return role;}
}



}
