import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl,Validators,AbstractControl,FormBuilder, FormArray} from '@angular/forms';
import { DateAdapter } from "@angular/material";
import { MatDialog} from '@angular/material';
import {AjustesPlaService} from '../../services/ajustes-pla.service';

@Component({
  selector: 'app-param-plat',
  templateUrl: './param-plat.component.html',
  styles: []
})
export class ParamPlatComponent implements OnInit {

  forma:FormGroup;
  minDate=new Date();
  dia:number;
  maxDate=new Date();
  hoyDate=new Date();
  dateControl:FormControl;
  diaSeleccionado:boolean=false;

  constructor(private fb: FormBuilder,private dateAdapter: DateAdapter<any>,public dialog: MatDialog,
    private bdService:AjustesPlaService) { 
    this.maxDate.setDate(this.maxDate.getDate() + 6);
    this.maxDate.setDate(this.maxDate.getDate() + 1);
    this.dateAdapter.setLocale('es');
    //poner limites de fecha:
   

    this.forma=this.fb.group({
      dateControl:['',Validators.required],
      capBog:['',Validators.required],
      capMed:['',Validators.required],

    });

  }
  
  ngOnInit() {
    this.dateControl = new FormControl('',[Validators.required]);
  }

  guardarCambios(){

    this.dia=this.forma.value.dateControl.getFullYear()*10000+
    (this.forma.value.dateControl.getMonth()+1)*100+this.forma.value.dateControl.getDate();
    
    console.log(this.forma);

    console.log(this.forma.value.dateControl);

    console.log(this.dia, this.forma.value);
    this.bdService.ajustesPlataforma(this.dia.toString(),this.forma.value).then(() => {
      console.log('Documento creado exitósamente!');
    }, (error) => {
      console.log(error);
    });

  }
  diaSeleccionadoP(){}

}
