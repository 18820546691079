import { Component, OnInit } from '@angular/core';
import {ServiceService} from '../../services/service.service';
import {FormGroup, FormControl, Validators,AbstractControl} from '@angular/forms';
import {AdminUsuService} from '../../services/admin-usu.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registro-admin',
  templateUrl: './registro-admin.component.html',
  styleUrls: ['./registro-admin.component.css']
})
export class RegistroAdminComponent implements OnInit {

forma:FormGroup;
otro:any;

  constructor(private _agendaService:ServiceService,private auth:AdminUsuService,private adminUsuService:AdminUsuService,private router: Router) { 

    this.forma=new FormGroup({

      'nombre':new FormControl('',[Validators.required,Validators.minLength(3)]),
      'apellido':new FormControl('',Validators.required),
      'email':new FormControl('',[Validators.required,
        Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")
      ],this.pequenaFuncion.bind(this)),
      'role':new FormControl(''),
      'password':new FormControl('',Validators.required),
      'password2':new FormControl(),
    });

    this.forma.controls['password2'].setValidators([
      Validators.required,
      this.noIgual.bind(this.forma)
    ])

  }

  noIgual(control:FormControl):{[s:string]:boolean}{
    let forma:any = this;
    if(control.value !==forma.controls['password'].value){
      return {
        noiguales:true
      }}
    return null;
  }

  cerrarSesion(){
    console.log("entro a la barra");
    this.adminUsuService.logout();
  }

  ngOnInit() {}
  guardarCambios(){
    this.auth.signup(this.forma.value.email, this.forma.value.password);

    if (this.forma.valid){
    this._agendaService.nuevoUsuario(this.forma.value).then(() => {
        console.log('Documento creado exitósamente!');
        this.forma.reset({
          nombre:"",
          apellido:"",
          email:"",
      });
      }, (error) => {
        console.log(error);
      });

    console.log(this.forma.value);
    console.log(this.forma);
}
    else {
      console.log("Formulario invalido");
    }
    }

    pequenaFuncion(control:AbstractControl){
      return this._agendaService.getCorreo(control.value).first().map((res) =>{
            this.otro = res.payload.data();
            return this.otro ? {existe:true}: null ;
            });
      }
}
