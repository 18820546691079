import { LOCALE_ID} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import localeEs from "@angular/common/locales/es";
import { registerLocaleData } from "@angular/common";
registerLocaleData(localeEs, "es");

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './component/login/login.component';
import {RegistroComponent} from './component/registro/registro.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import {ServiceService} from './services/service.service';
import {AjustesPlaService} from './services/ajustes-pla.service';
import {CrearMuellesService} from './services/crear-muelles.service';
// import {AuthService} from './servicios/auth.service';
// import {GuardService} from './servicios/guard.service';
// import {GuardAdminServiceService} from './servicios/guard-admin-service.service';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import {HttpClientModule} from '@angular/common/http'

import { AgendaComponent } from './component/agenda/agenda.component';
import { AgendaVisualComponent } from './component/agenda-visual/agenda-visual.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule, MatInputModule,
 MatSidenavModule, MatIconModule, MatListModule,MatNativeDateModule} from '@angular/material';
import { MatFormFieldModule, MatCheckboxModule} from '@angular/material';
import {MatGridListModule} from '@angular/material/grid-list'
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import { MyDialogComponent } from './component/agenda-visual/my-dialog/my-dialog.component';
import { CitasAsignadasComponent } from './component/citas-asignadas/citas-asignadas.component';
import { RegistroAdminComponent } from './component/registro-admin/registro-admin.component';
import { BarraLateralComponent } from './component/barra-lateral/barra-lateral.component';
import { CitasDiaPlaComponent } from './component/citas-dia-pla/citas-dia-pla.component';
import { LoginAuthComponent } from './component/login-auth/login-auth.component';
import { HomeComponent } from './component/home/home.component';
import { UsersComponent } from './component/users/users.component';
import { BarraOperadorComponent } from './component/barra-operador/barra-operador.component';
import { HomeOperalogComponent } from './component/home-operalog/home-operalog.component';
import { LayoutModule } from '@angular/cdk/layout';
import { PlataformaPipe } from './pipes/plataforma.pipe';
import { FechaHumanaPipe } from './fecha-humana.pipe';
import { FooterComponent } from './component/footer/footer.component';
import { FeedbackComponent } from './component/feedback/feedback.component';
import { CambiopassComponent } from './component/cambiopass/cambiopass.component';
import { AgendaopComponent } from './component/agendaop/agendaop.component';
import { ModificarProvComponent } from './component/modificar-prov/modificar-prov.component';
import { ParamPlatComponent } from './component/param-plat/param-plat.component';
import { VerParamComponent } from './component/ver-param/ver-param.component';
import { IndicadoresComponent } from './component/indicadores/indicadores.component';
import { ContactosComponent } from './component/contactos/contactos.component';
import { ExcelLoaderComponent } from './component/excel-loader/excel-loader.component';
import { HoraPipe } from './pipes/hora.pipe';
import { AyudaComponent } from './component/ayuda/ayuda.component';
import { BorrarIndicadoresComponent } from './component/borrar-indicadores/borrar-indicadores.component';
import { IndicadoresAdmComponent } from './component/indicadores-adm/indicadores-adm.component';
import { HoraMilPipe } from './pipes/hora-mil.pipe';
import { InIndicadorComponent } from './component/in-indicador/in-indicador.component';
import { DialogTrazaComponent } from './component/in-indicador/dialog-traza/dialog-traza.component';
import { SegOperadorComponent } from './component/seg-operador/seg-operador.component';
import { SinCitaComponent } from './component/sin-cita/sin-cita.component';
import { BarraConsultaComponent } from './component/consulta/barra-consulta/barra-consulta.component';
import { HomeConsultaComponent } from './component/consulta/home-consulta/home-consulta.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginAuthComponent,
    LoginComponent,
    RegistroComponent,
    BarraLateralComponent,
    BarraOperadorComponent,
    HomeComponent,
    HomeOperalogComponent,
    AgendaComponent,
    AgendaVisualComponent,
    MyDialogComponent,
    CitasAsignadasComponent,CitasDiaPlaComponent,
    RegistroAdminComponent,
    UsersComponent,
    PlataformaPipe,
    FechaHumanaPipe,
    FooterComponent,
    FeedbackComponent,
    CambiopassComponent,
    AgendaopComponent,
    ModificarProvComponent,
    ParamPlatComponent,
    VerParamComponent,
    IndicadoresComponent,
    ContactosComponent,
    ExcelLoaderComponent,
    HoraPipe,
    AyudaComponent,
    BorrarIndicadoresComponent,
    IndicadoresAdmComponent,
    HoraMilPipe,
    InIndicadorComponent,
    DialogTrazaComponent,
    SegOperadorComponent,
    SinCitaComponent,
    BarraConsultaComponent,
    HomeConsultaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatNativeDateModule,
    //MatMomentDateModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatDialogModule,
    LayoutModule
  ],
  entryComponents: [MyDialogComponent,DialogTrazaComponent],
  providers: [ServiceService,CrearMuellesService,AjustesPlaService,{ provide: LOCALE_ID, useValue: "es" }],
  bootstrap: [AppComponent]
})
export class AppModule { }
