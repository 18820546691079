import { Injectable } from '@angular/core';
import { AngularFirestore,AngularFirestoreCollection } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
export interface Item { id: string; proveedor: string; }

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  private itemsCollection: AngularFirestoreCollection<Item>;

  constructor(private db:AngularFirestore) {}
  
  public formProveedores(){
  return this.db.collection("proveedores");}
  
  
  public nuevoUsuario(usuario:any){
  return this.db.collection('usuarios').doc(usuario.email).set(usuario);}
  
  public addItem(proveedor: string) {
  return this.db.collection(proveedor).doc("data1").set({ name: "username1" })
  }
  //actualizar cada franja por muelle por dia
  public updateFranjaDiaejemplo(dia$:string,plataforma$:string,obj:any) {
    return this.db.collection(dia$).doc(plataforma$).update(obj);
  }
  
  //Prender y apagar el flag para el dialogo de pedir cita:
  public putFlagDialogPlatDia(dia$:string,plataforma$:string,flag$:string,iMu:number) {
    return this.db.collection(dia$).doc(plataforma$ + 'flag' + iMu).set({ name:flag$})
    }
  public getflagDialogPlatDia(dia$:string,plataforma$:string,iMu:number) {
      return this.db.collection(dia$).doc(plataforma$ + 'flag' + iMu).snapshotChanges();}
  
  public getNoticias() {
      return this.db.collection('noticias').doc('noticias1').snapshotChanges();}

  public getCorreo(correo$:string) {
  return this.db.collection('usuarios').doc(correo$).snapshotChanges();}
  
  public buscarCorreoExiste(correo$:string) {
  return this.db.collection('usuarios', ref => {
    return ref.where('correo', '==', correo$)})
  }
  
  public crearMuellesDia(arreglo$:any,dia$:string,plataforma$:string){
    return this.db.collection(dia$).doc(plataforma$).set(arreglo$);
  }
  // public updateFranjaMuellePlatDia(franja$:any,muelle$:number,dia$:string,plataforma$:string){
  //   return this.db.collection(dia$).doc(plataforma$).set(arreglo$);
  // }
  
  // Revisa si sirve para algo
  // public getMuellesDia(plataforma$:string,dia$:number){
  //   return this.db.collection(dia$.toString()).doc("Muelles");
  // }
  
  public getDia(dia:string,codPla:string) {
    return this.db.collection(dia).doc(codPla).snapshotChanges();}

  public cleanCitaMuelleDia(dia:string,codPla:string) {
      return this.db.collection(dia).doc(codPla).snapshotChanges();}
    
  
  public correoContrasena(correo$:string,contrasena$:string) {
    return this.db.collection('usuarios', ref => {
      return ref.where('email', '==', correo$).where('password', '==', contrasena$)})
    }
  public getUsu(correo$:string) {
    return this.db.collection('usuarios', ref => {
    return ref.where('email', '==', correo$)})
  }
  
  public crearCita(dia:number,cita:any) {
    return this.db.collection(dia.toString()).add(cita);
   }

  public citaReportada(dia$:string,id$:string){
    return this.db.collection(dia$).doc(id$).update({reportado:"Si"});}
  
  public getCitas(proveedor$:string,dia$:string) {
    return this.db.collection(dia$, ref => {
      return ref.where('empresa', '==', proveedor$)}).snapshotChanges();
    }
  
    public usuarios(){
      return this.db.collection("usuarios",ref => {
        return ref.where('productividad', '>',0)}).snapshotChanges();
    }
    public usuarios1(){
      return this.db.collection("usuarios",ref => {
        return ref.where('productividad', '>',0)}).ref.get();
    }
  
  public getCitasDiaPla(dia$:string,plat$:number){
    return this.db.collection(dia$, ref => {
      return ref.where('plataforma', '==', plat$)}).snapshotChanges();
  }
// buscar indicadores para mostrarle al proveedor

  public getIndicadores(proveedor$:string,plat$:number){
    return this.db.collection(proveedor$, ref => {
      return ref.where('plataforma', '==', plat$)}).snapshotChanges();}
  
  public borrarIndicador(proveedor$:string,diaPla$:string){
    return this.db.collection(proveedor$).doc(diaPla$).delete();
  }
  
  public valCitaPlaDia(proveedor$:string,dia$:string,plat$:number) {
    return this.db.collection(dia$, ref => {
      return ref.where('empresa', '==', proveedor$).where('plataforma', '==', plat$)}).snapshotChanges();
      }
  
  public borrarCita(id$:string,dia$:string){
      return this.db.collection(dia$).doc(id$).delete();
    }
  
  public borrarUsuario(id$:string){
      return this.db.collection('usuarios').doc(id$).delete();
    }
  
  public borrarUserDB(id$:string){
      return this.db.collection('usuarios').doc(id$).delete();
    }

  public getAjustesPlataforma() {
      return this.db.collection('parametrosPat').doc('parametros').snapshotChanges();}
  
  // Subir indicador de proveedor en excel o por medio de la APP
  public subirExcel(proveedor$:string,dia$:string,cita$:any){
    return this.db.collection(proveedor$).doc(dia$).set(cita$);
  }

  public borrarInd(proveedor$:string,diaPla$:string){
    return this.db.collection(proveedor$).doc(diaPla$).delete();
  }
  
    // Subir indicador del OPERADOR por medio de la APPs
    public crearCarpeta(plataforma$:string,dia$:string,registro$:any){
      return this.db.collection(plataforma$).doc(dia$).set(registro$);
    }
    public indOperador(plataforma$:string,dia$:string,registro$:any){
      return this.db.collection(plataforma$).doc(dia$).update(registro$);
    }
    public borrarIndOperador(plataforma$:string,diaPla$:string){
      return this.db.collection(plataforma$).doc(diaPla$).update({
        nada: firebase.firestore.FieldValue.delete()});
    }
    public borrarIndOperador1(plataforma$:string,diaPla$:string,field$:string){
      return this.db.collection(plataforma$).doc(diaPla$).update({
        [field$]: firebase.firestore.FieldValue.delete()});
    }
    
    public getseguimientoOperador(plataforma$:string,dia$:string) {
      return this.db.collection(plataforma$).doc(dia$).snapshotChanges();}
}