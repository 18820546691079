import { Component, OnInit } from '@angular/core';
import {ServiceService} from '../../services/service.service';
import {FormGroup, FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-excel-loader',
  templateUrl: './excel-loader.component.html',
  styleUrls: ['./excel-loader.component.css']
})
export class ExcelLoaderComponent implements OnInit {

  forma:FormGroup;


  constructor(private dbService:ServiceService) {

    this.forma=new FormGroup({
      'password':new FormControl('',Validators.required)
    });

    

  }


  ngOnInit() {
  }

  okContrasena(){
    if (this.forma.controls.password.value == "250279"){

    var usercollection = [
      {
        "plataforma": 95,
        "dia": "2021-08-23T05:00:00.000Z",
        "hora_cita": 0.25,
        "proveedor": "PROCTER & GAMBLE COLOMBIA LTDA",
        "cajas-informadas": 439,
        "frecuencia": "Si",
        "cajas_reales": 512,
        "hora_llegada": 0.272222222222222,
        "cumplio_cita": "Si"
      }
    ];
    
    
    
    
    
    for (let i:number=0; i<usercollection.length;i++) {
        this.dbService.subirExcel(usercollection[i].proveedor,usercollection[i].dia.substr(0,10) + "-" + usercollection[i].plataforma,usercollection[i]).then(() => {
          console.log('Documento creado o ajustado exitósamente!');
        }, (error) => {
           console.error(error);
        });
    
       }// Fin del For
      }//Fin IF contraseña
      else{console.log ("Contraseña errada")}
  }
} 
